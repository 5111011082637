import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FilterKeys } from "../components/LocationFilterBar";
import { SortDirection } from "../filters";

interface LocationFilters {
  sortDirection: SortDirection;
  selectedCompany: string;
  selectedLocation: string;
  selectedBuilding: string;
}
export function useLocationFilters(): LocationFilters {
  const location = useLocation();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>("ASC");

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    setSelectedBuilding(urlSearchParams.get(FilterKeys.Building) ?? "");
    setSelectedCompany(urlSearchParams.get(FilterKeys.Company) ?? "");
    setSelectedLocation(urlSearchParams.get(FilterKeys.Location) ?? "");
    setSortDirection(
      (urlSearchParams.get(FilterKeys.SortDirection) as SortDirection) ?? "ASC"
    );
  }, [location.search]);

  return useMemo(
    () => ({
      selectedCompany,
      selectedLocation,
      selectedBuilding,
      sortDirection,
    }),
    [selectedCompany, selectedLocation, selectedBuilding, sortDirection]
  );
}
