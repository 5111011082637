import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { sendHealthCheck } from "../../api/lockers";
import CopyToClipboardButton from "../../common/CopyToClipboardButton";
import ItemCard from "../../common/ItemCard";
import { Locker } from "../../entities";
import { useAppDispatch } from "../../hooks";
import { refreshLocker } from "../../store/lockersSlice";
import "./style.scss";

interface OwnProps {
  item: Locker.Type;
  onEdit: () => void;
  onSendHealthCheck: () => void;
}
const LockerItem = ({ item, onSendHealthCheck, onEdit }: OwnProps) => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();

  const handleRefreshLocker = useCallback(() => {
    dispatch(refreshLocker(item.id));
  }, [item]);

  const statusClassName = useMemo(() => {
    if (item.on) return "active";
    return "inactive";
  }, [item]);

  return (
    <div className="locker-item-conteiner">
      <ItemCard>
        <Box display="flex" alignItems="center">
          <div className={classNames("item", "large")}>
            <TextItem
              label={getTranslationByLabel("locker")}
              description={item.name}
            />
          </div>
          <div className={classNames("item", "large")}>
            <TextItem
              label={getTranslationByLabel("location")}
              description={item.location.name}
            />
          </div>
          <div className={classNames("item", "large")}>
            <Typography
              component="p"
              textAlign="left"
              textTransform="uppercase"
              variant="caption"
              color="primary"
            >
              {getTranslationByLabel("health-status")}
            </Typography>
            <div className={classNames(statusClassName, "status-item")}>
              <Typography
                textAlign="left"
                component="p"
                variant="caption"
                color={item.on ? "white" : "primary"}
              >
                {item.on ? "active" : "inactive"}
              </Typography>
            </div>
          </div>
          <Button
            className={classNames("extra-small", "button")}
            variant="outlined"
            fullWidth
            onClick={handleRefreshLocker}
          >
            <FontAwesomeIcon size="2x" icon={faArrowRotateRight} />
          </Button>
          <CopyToClipboardButton
            className={classNames("small", "button")}
            fullWidth
            variant="outlined"
            valueToCopy={item.id}
          />
          <Button
            className={classNames("large", "button")}
            variant="outlined"
            fullWidth
            onClick={() => {
              sendHealthCheck(item.id);
              onSendHealthCheck();
            }}
          >
            {getTranslationByLabel("send-health-check")}
          </Button>
          <Button
            className={classNames("large", "button")}
            variant="outlined"
            fullWidth
            onClick={onEdit}
          >
            {getTranslationByLabel("manage")}
          </Button>
        </Box>
      </ItemCard>
    </div>
  );
};

interface TextItemProps {
  label: string;
  description: string;
}

const TextItem = ({ label, description }: TextItemProps) => {
  return (
    <div className={classNames("item", "small")}>
      <Typography
        component="p"
        textAlign="left"
        textTransform="uppercase"
        variant="caption"
        color="primary"
      >
        {label}
      </Typography>
      <Typography
        className="description-label"
        textAlign="left"
        variant="h6"
        color="primary"
      >
        {description}
      </Typography>
    </div>
  );
};

export default LockerItem;
