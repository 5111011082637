import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import { Field, Formik, FormikHelpers } from "formik";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FormikTextField from "../common/FormikTextField";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import "../components/SigninForm/style.scss";
import { useAppDispatch } from "../hooks";
import { LOGIN_PATH } from "../routes";
import {
  EnumNotificationType,
  showNotification,
} from "../store/notificationSlice";
import { getEmailValidationSchema } from "../validation";

interface FormikValues {
  email: string;
  submitError: string;
}

const ForgotPassword = () => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();
  const history = useHistory();

  const initialValues = useMemo<FormikValues>(
    () => ({
      email: "",
      submitError: "",
    }),
    []
  );

  const handleSubmit = useCallback(
    (
      { email }: FormikValues,
      { setSubmitting, setFieldValue }: FormikHelpers<FormikValues>
    ) => {
      setSubmitting(true);
      oktaAuth
        .forgotPassword({
          username: email,
          factorType: "EMAIL",
        })
        .then((result) => {
          console.log("Okay -> ", result);
          dispatch(
            showNotification({
              text: getTranslationByLabel("password-resset-success", { email }),
              type: EnumNotificationType.Success,
            })
          );
        })
        .catch((error) => {
          setFieldValue("submitError", "Error reseting password!");
          dispatch(
            showNotification({
              text: error?.response?.data ?? "Something went wrong!",
              type: EnumNotificationType.Error,
            })
          );
        });
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
    },
    [oktaAuth]
  );
  return (
    <div className="signin-form-container">
      <Formik
        initialValues={initialValues}
        validationSchema={getEmailValidationSchema(
          getTranslationByLabel("invalid-email"),
          getTranslationByLabel("please-enter-email")
        )}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values: { email, submitError }, submitForm }) => (
          <WithLoadingBackdrop open={isSubmitting}>
            <div className="form">
              <div className="back-button-container">
                <IconButton
                  onClick={() => {
                    history.push(LOGIN_PATH);
                  }}
                  color="primary"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <Typography
                    variant="h6"
                    color="primary"
                    className="back-button-label"
                  >
                    {getTranslationByLabel("back")}
                  </Typography>
                </IconButton>
              </div>
              <Typography className="login-label" variant="h5" color="primary">
                {getTranslationByLabel("forgot-password-title-label")}
              </Typography>
              <Field
                component={FormikTextField}
                className="input"
                name="email"
                label={getTranslationByLabel("enter-email")}
                fullWidth
                InputProps={{
                  inputProps: {
                    type: "email",
                  },
                }}
              />
              <Button
                className="button"
                onClick={submitForm}
                variant="contained"
                color="primary"
                disabled={isSubmitting || !email}
              >
                {getTranslationByLabel("submit")}
              </Button>
              {submitError && (
                <Typography variant="caption" color="error">
                  {submitError}
                </Typography>
              )}
            </div>
          </WithLoadingBackdrop>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
