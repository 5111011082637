import { useOktaAuth } from "@okta/okta-react";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  cleanAccessToken,
  setInterceptApiRequest,
  setInterceptApiResponse,
  setSignupSuccess,
} from "../api";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import { useAppDispatch, useAppSelector } from "../hooks";
import { LOGIN_PATH } from "../routes";
import { getSelfUser, selectSelfUser, clearState } from "../store/userSlice";
import Home from "./Home";

const HomeProtected = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const selfUser = useAppSelector(selectSelfUser);
  const shouldHardLogout = useAppSelector(
    (state) => state.user.shouldHardLogout
  );

  useEffect(() => {
    // Refresh the token on every 1hr.
    const interval = setInterval(renewTokens, 1000 * 60 * 60);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (authState && authState.isAuthenticated && !selfUser) {
      const accessToken = oktaAuth.getAccessToken();
      if (accessToken) {
        dispatch(getSelfUser(accessToken));
      }
    }
    if (authState && !authState.isAuthenticated) {
      renewTokens();
    }
  }, [authState, selfUser]);

  useEffect(() => {
    if (shouldHardLogout) {
      signOut();
    }
  }, [oktaAuth, shouldHardLogout]);

  const renewTokens = useCallback(async () => {
    console.log("Renew tokens!");
    try {
      const tokens = await oktaAuth.tokenManager.getTokens();
      if (tokens.accessToken) {
        const newToken = await oktaAuth.tokenManager.renewToken(
          tokens.accessToken
        );
        // @ts-ignore
        if (newToken && newToken.accessToken) {
          // @ts-ignore
          console.log(newToken.accessToken);
          // @ts-ignore
          dispatch(setSignupSuccess(newToken.accessToken));
          // @ts-ignore
          await setInterceptApiRequest(token);
          await setInterceptApiResponse();
        }
      }
    } catch (error) {
      console.error("Can not refresh token!");
      signOut();
    }
  }, [oktaAuth]);

  const signOut = useCallback(async () => {
    cleanAccessToken();
    await oktaAuth.signOut({
      clearTokensBeforeRedirect: true,
    });
    setTimeout(() => {
      history.replace(LOGIN_PATH);
    }, 0);
  }, [oktaAuth, history]);

  if (!authState || !selfUser) {
    return <WithLoadingBackdrop open />;
  }

  return <Home />;
};

export default HomeProtected;
