import { Location } from "./";

export const getUserTypeText = (type: User.UserType) => {
  switch (type) {
    case "MARVELOCK_ADMIN":
      return "Marvelock Admin";
    case "RESIDENT":
      return "Resident";
    case "SITE_MANAGER":
      return "Site Manager";
    case "BUILDING_MANAGER":
      return "Building manager";
    case "USER":
      return "User";
    case "SKIER":
      return "Skier";
    case "CUSTOMER_ADMIN":
      return "Customer Admin";
    case "SERVICE_PROVIDER":
      return "Service Provider";
    case "MAINTAINER":
      return "Maintainer";
  }
};
export namespace User {
  export type UserType =
    | "MARVELOCK_ADMIN"
    | "RESIDENT"
    | "SITE_MANAGER"
    | "BUILDING_MANAGER"
    | "USER"
    | "SKIER"
    | "CUSTOMER_ADMIN"
    | "SERVICE_PROVIDER"
    | "MAINTAINER";

  export type UserConfiguration = {
    id?: string;
    lockerEmailNotificationsEnabled: boolean;
    lockerAppNotificationsEnabled: boolean;
    smsLockerNotificationsEnabled: boolean;
    emailNotificationsEnabled: boolean;
    appNotificationsEnabled: boolean;
    smsNotificationsEnabled: boolean;
    language: "en" | "fr";
  };

  export type Type = {
    id: string;
    email: string;
    type: UserType;
    badgeId?: string;
    organization: string;
    isEnabled: boolean;
    locations: Location.Type[];
    firstName: string;
    middleName: string;
    lastName: string;
    isHandicap: boolean;
    phone: string;
    userConfiguration?: UserConfiguration;
    lockerFeatureEnabled: boolean;
    notificationFeatureEnabled: boolean;
    smsFeatureEnabled: boolean;
    accessToken: string;
  };

  export type Self = Omit<User.Type, "type" | "locations"> & {
    role: UserType;
    userLocations: Location.Type[];
  };

  export const parseRawUser = (rawUser: any): User.Type | User.Self => {
    return {
      ...rawUser,
      id: rawUser.userId || rawUser.id,
      locations: rawUser.locations || [],
      firstName: rawUser.firstName ?? "N/A",
      middleName: rawUser.middleName ?? "N/A",
      lastName: rawUser.lastName ?? "N/A",
      isHandicap: rawUser.isHandicap ?? false,
      phone: rawUser.phone ?? "N/A",
      userConfiguration: rawUser.userConfiguration,
      lockerFeatureEnabled: rawUser.lockerFeatureEnabled ?? false,
      notificationFeatureEnabled: rawUser.notificationFeatureEnabled ?? false,
      smsFeatureEnabled: rawUser.smsFeatureEnabled ?? false,
    };
  };
}

export default User;
