import { Backdrop, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import WithLoadingBackdrop from "../../common/WithLoadingBackdrop";
import TopBar from "../../components/TopBar";
import { useAppSelector } from "../../hooks";
import { useUserAccess } from "../../hooks/useUserAccess";
import {
  APPLICATION_PATH,
  DELIVERY_PATH,
  LOCATIONS_PATH,
  LOCKERS_PATH,
  NOTIFICATIONS_PATH,
  REPORTS_PATH,
  USERS_PATH,
} from "../../routes";
import { selectSelfUser } from "../../store/userSlice";
import Locations from "../Locations";
import Lockers from "../Lockers";
import Notifications from "../Notifications";
import Reports from "../Reports";
import ServiceOrders from "../ServiceOrders";
import Users from "../Users";
import "./style.scss";

const Home = () => {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const userAccess = useUserAccess();
  const loading = useAppSelector((state) => state.user.loading);
  const selfUser = useAppSelector(selectSelfUser);
  const [settingsOpened, setSettingsOpened] = useState(false);

  const {
    hasNotificationsAccess,
    hasServiceOrdersAccess,
    hasLockersAccess,
    hasUsersAccess,
    hasLocationsAccess,
    hasReportsAccess,
  } = userAccess;

  useEffect(() => {
    if (selfUser) {
      i18n.changeLanguage(selfUser.userConfiguration?.language);
    }
  }, [selfUser]);

  return (
    <WithLoadingBackdrop open={loading}>
      <div className="home-container">
        <TopBar
          onToggleSettings={() => {
            setSettingsOpened((prevState) => !prevState);
          }}
        />
        <div className="backdrop-content">
          <Backdrop
            style={{
              zIndex: theme.zIndex.drawer + 1,
              color: "#fff",
              position: "absolute",
            }}
            open={settingsOpened}
          />
          <div className="content">
            <Switch>
              {hasNotificationsAccess && (
                <Route
                  exact
                  path={NOTIFICATIONS_PATH}
                  component={Notifications}
                />
              )}
              {hasServiceOrdersAccess && (
                <Route exact path={DELIVERY_PATH} component={ServiceOrders} />
              )}
              {hasUsersAccess && (
                <Route exact path={USERS_PATH} component={Users} />
              )}
              {hasLocationsAccess && (
                <Route exact path={LOCATIONS_PATH} component={Locations} />
              )}
              {hasLockersAccess && (
                <Route exact path={LOCKERS_PATH} component={Lockers} />
              )}
              {hasReportsAccess && (
                <Route exact path={REPORTS_PATH} component={Reports} />
              )}
              <Route exact path={APPLICATION_PATH}>
                {selfUser && (
                  <Redirect
                    to={
                      selfUser.notificationFeatureEnabled
                        ? NOTIFICATIONS_PATH
                        : DELIVERY_PATH
                    }
                  />
                )}
              </Route>
              {/* <Route path="*">
                {selfUser && (
                  <Redirect
                    to={
                      selfUser.notificationFeatureEnabled
                        ? NOTIFICATIONS_PATH
                        : DELIVERY_PATH
                    }
                  />
                )}
              </Route> */}
            </Switch>
          </div>
        </div>
      </div>
    </WithLoadingBackdrop>
  );
};

export default Home;
