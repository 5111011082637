import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/system/Box";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bin } from "../entities";
import { parseBinSizeKey } from "../entities/Bin";
import { DoorStatus } from "../entities/Bin";

interface OwnProps {
  open: boolean;
  bin?: Partial<Bin.Type>;
  onClose: () => void;
  onSubmit: (item: Partial<Bin.Type>) => void;
  onRemoveBin: () => void;
}

const AddBinDialog = ({
  open,
  bin,
  onSubmit,
  onRemoveBin,
  onClose,
}: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const [binState, setBinState] = useState<Partial<Bin.Type>>(
    bin ?? {
      active: false,
      isHandicap: false,
    }
  );

  useEffect(() => {
    if (!open) {
      setBinState({
        active: false,
        isHandicap: false,
      });
    }
  }, [open]);

  useEffect(() => {
    if (bin) {
      setBinState(bin);
    }
  }, [bin]);

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">
            {getTranslationByLabel(bin ? "update-bin" : "add-new-bin")}
          </Typography>
          <IconButton aria-label="close" onClick={onClose} color="primary">
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography
            component="p"
            textAlign="left"
            textTransform="uppercase"
            variant="caption"
            color="primary"
          >
            {getTranslationByLabel("bin-number")}
          </Typography>
          <TextField
            fullWidth
            placeholder={`${getTranslationByLabel("enter-bin-number")}...`}
            value={binState.number ? String(binState.number) : ""}
            onChange={(event) => {
              if (isNaN(Number(event.target.value))) {
                return;
              }
              setBinState((prevState) => ({
                ...prevState,
                number: Number(event.target.value),
              }));
            }}
          />
          <Typography
            component="p"
            textAlign="left"
            textTransform="uppercase"
            variant="caption"
            color="primary"
          >
            {getTranslationByLabel("bin-size")}
          </Typography>
          <Select
            value={binState.binDoor ? String(binState.binDoor.size) : undefined}
            displayEmpty
            renderValue={(value) => {
              if (value) {
                return getTranslationByLabel(parseBinSizeKey(Number(value)));
              }
              return `${getTranslationByLabel("select-bin-size")}...`;
            }}
            onChange={(event: SelectChangeEvent) => {
              setBinState((prevState) => ({
                ...prevState,
                binDoor: {
                  status: prevState.binDoor
                    ? prevState.binDoor.status
                    : DoorStatus.Close,
                  size: Number(event.target.value),
                },
              }));
            }}
          >
            <MenuItem value={1}>{getTranslationByLabel("small")}</MenuItem>
            <MenuItem value={2}>{getTranslationByLabel("medium")}</MenuItem>
            <MenuItem value={3}>{getTranslationByLabel("large")}</MenuItem>
            <MenuItem value={4}>{getTranslationByLabel("xl")}</MenuItem>
            <MenuItem value={5}>{getTranslationByLabel("xxl")}</MenuItem>
          </Select>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!binState.isHandicap}
                  onChange={(e) => {
                    setBinState((prevState) => ({
                      ...prevState,
                      isHandicap: e.target.checked,
                    }));
                  }}
                />
              }
              label={getTranslationByLabel("bin-handicap-enabled")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!binState.active}
                  onChange={(e) => {
                    setBinState((prevState) => ({
                      ...prevState,
                      active: e.target.checked,
                    }));
                  }}
                />
              }
              label={getTranslationByLabel("bin-active")}
            />
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions sx={{ alignItems: "baseline" }}>
        {bin && (
          <Button className="button" variant="text" onClick={onRemoveBin}>
            {getTranslationByLabel("remove-bin")}
          </Button>
        )}
        <Button
          variant="contained"
          autoFocus
          disabled={!binState.number || !binState.binDoor}
          onClick={() => {
            onSubmit(binState);
          }}
        >
          {getTranslationByLabel(bin ? "update-bin" : "add-bin")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBinDialog;
