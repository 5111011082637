import axios from "axios";
import { PartialApiItemsResponse } from ".";
import { Report } from "../entities";
import { SortDirection } from "../filters";

interface ReportsResponseItem
  extends Omit<Report.Type, "serviceOrder" | "user"> {
  serviceOrderId: string;
  userAssignedTo: string;
}
interface ReportsResponse extends PartialApiItemsResponse {
  content: ReportsResponseItem[];
}

export const fetchReports = (
  page: number,
  items: number,
  sortDirection: SortDirection = "ASC",
  companyName?: string,
  buildingName?: string,
  locationName?: string
) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("page", String(page));
  urlSearchParams.append("items", String(items));
  urlSearchParams.append("sortDirection", sortDirection);
  if (companyName) {
    urlSearchParams.append("company-name", companyName);
  }
  if (buildingName) {
    urlSearchParams.append("building-name", buildingName);
  }
  if (locationName) {
    urlSearchParams.append("location-name", locationName);
  }
  return new Promise<ReportsResponse>((resolve, reject) => {
    axios
      .get(`/v1/service-order-report?${urlSearchParams.toString()}`)
      .then((response) => {
        resolve(response.data ?? {});
      })
      .catch((error) => {
        reject(error);
      });
  });
};
