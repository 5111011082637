import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "../../common/CopyToClipboardButton";
import ItemCard from "../../common/ItemCard";
import { Report } from "../../entities";
import "./style.scss";

interface OwnProps {
  item: Report.Type;
}
const ReportItem = ({ item }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const bin = useMemo(() => item.serviceOrder.bins[0], [item]);
  const status = useMemo(
    () => (item.serviceOrder.state === "active" ? "available" : "delivered"),
    [item]
  );
  const statusClassName = useMemo(() => {
    if (status === "available") {
      return "active";
    }
    if (status === "delivered" || status === "inactive") {
      return "inactive";
    }
    return "attention";
  }, [status]);

  return (
    <div className="report-item-conteiner">
      <ItemCard>
        <Box display="flex" alignItems="center">
          <div className={classNames("item", "large")}>
            <div className={classNames(statusClassName, "status-item")}>
              <Typography
                textAlign="left"
                component="p"
                variant="caption"
                color={status === "available" ? "white" : "primary"}
              >
                {status}
              </Typography>
            </div>
            <Typography textAlign="left" variant="h6" color="primary">
              {bin.locker.location.name}
            </Typography>
            <Typography
              textAlign="left"
              variant="caption"
              color="primary"
              component="p"
            >
              {bin.locker.location.building}
            </Typography>
          </div>
          <div className={classNames("item", "large")}>
            <div className={classNames("item", "small")}>
              <Typography
                component="p"
                textAlign="left"
                textTransform="uppercase"
                variant="caption"
                color="primary"
              >
                {getTranslationByLabel("duration")}
              </Typography>
              <Typography
                className="description-label"
                textAlign="left"
                variant="h6"
                color="primary"
              >
                {item.serviceOrderEffectiveDate.fromNow()}
              </Typography>
            </div>
          </div>
          <div className={classNames("item", "small")}>
            <TextItem
              label={getTranslationByLabel("company")}
              description={item.companyName}
            />
          </div>
          <div className={classNames("item", "large")}>
            <TextItem
              label={getTranslationByLabel("assigned-to")}
              description={getTranslationByLabel(item.user.type)}
            />
          </div>
          <div className={classNames("item", "small")}>
            <CopyToClipboardButton
              fullWidth
              variant="outlined"
              valueToCopy={item.serviceOrder.id}
            />
          </div>
        </Box>
      </ItemCard>
    </div>
  );
};

interface TextItemProps {
  label: string;
  description: string;
}

const TextItem = ({ label, description }: TextItemProps) => {
  return (
    <div className={classNames("item", "small")}>
      <Typography
        component="p"
        textAlign="left"
        textTransform="uppercase"
        variant="caption"
        color="primary"
      >
        {label}
      </Typography>
      <Typography
        textAlign="left"
        variant="body1"
        color="primary"
        className="text-item-label"
      >
        {description}
      </Typography>
    </div>
  );
};

export default ReportItem;
