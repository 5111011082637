import { Box, Pagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PageWrapper from "../common/PageWrapper";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import LocationDialog from "../components/LocationDialog";
import LocationFilterBar from "../components/LocationFilterBar";
import LocationItem from "../components/LocationItem";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useLocationFilters } from "../hooks/useLocationFilters";
import { usePagination } from "../hooks/usePagination";
import {
  getLocations,
  selectIsLoading,
  selectItems,
  selectPages,
} from "../store/locationsSlice";

const ITEMS_PER_PAGE = 10;

const Locations = () => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();
  const location = useLocation();
  const isLoading = useAppSelector(selectIsLoading);
  const items = useAppSelector(selectItems);
  const pages = useAppSelector(selectPages);
  // const count = useAppSelector(selectItemsCount);
  const [page, setPage] = usePagination();
  const [dialogOpened, setDialogOpened] = useState(false);
  const [editId, setEditId] = useState("");
  const { sortDirection, selectedCompany, selectedLocation, selectedBuilding } =
    useLocationFilters();

  // const filterItems: FilterItem[] = useMemo(
  //   () => [
  //     {
  //       key: "all",
  //       label: getTranslationByLabel("all"),
  //     },
  //     {
  //       key: "city",
  //       label: getTranslationByLabel("city"),
  //     },
  //     {
  //       key: "company-name",
  //       label: getTranslationByLabel("company-name"),
  //     },
  //   ],
  //   []
  // );

  // const filterBarLabel = useMemo(() => {
  //   if (isLoading || !page) {
  //     return getTranslationByLabel("loading");
  //   }
  //   let labelNumber = page * 10;
  //   if (count < 10 || labelNumber > count) {
  //     labelNumber = count;
  //   }
  //   return `${labelNumber}/${count} ${getTranslationByLabel(
  //     "locations-menu"
  //   ).toLowerCase()}`;
  // }, [count, page, isLoading, i18n.language]);

  useEffect(() => {
    // Decrease and increase with 1, because the API has zero based pagination filter
    if (page) {
      dispatch(
        getLocations({
          page: page - 1,
          itemsPerPage: ITEMS_PER_PAGE,
          filters: {
            companyName: selectedCompany,
            locationName: selectedLocation,
            buildingName: selectedBuilding,
            sortDirection,
          },
        })
      );
    }
  }, [
    page,
    selectedCompany,
    selectedLocation,
    selectedBuilding,
    sortDirection,
  ]);

  return (
    <WithLoadingBackdrop open={isLoading}>
      <LocationFilterBar
        withCreateButton
        onCreateClick={() => {
          setDialogOpened(true);
        }}
      />
      {/* <FilterBar
        withButton
        label={filterBarLabel}
        items={filterItems}
        onClick={() => {
          setDialogOpened(true);
        }}
      /> */}
      <PageWrapper>
        {items.map((item) => (
          <LocationItem
            key={item.id}
            item={item}
            onEdit={() => {
              setEditId(item.id);
              setDialogOpened(true);
            }}
          />
        ))}
        {!items.length && !isLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
          >
            <Typography variant="h3" color="textSecondary">
              {getTranslationByLabel("no-items")}
            </Typography>
          </Box>
        )}
        {!!items.length && !isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" p={1}>
            <Pagination
              onChange={(_, page) => {
                // Decrease and increase with 1, because the API has zero based pagination filter
                setPage(page, location.search);
              }}
              page={page ?? 1}
              count={pages}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Box>
        )}
      </PageWrapper>
      <LocationDialog
        open={dialogOpened}
        id={editId}
        onClose={(shouldRefetch?: boolean) => {
          setEditId("");
          setDialogOpened(false);
          if (shouldRefetch) {
            dispatch(
              getLocations({
                page: page ? page - 1 : 0,
                itemsPerPage: ITEMS_PER_PAGE,
              })
            );
          }
        }}
      />
    </WithLoadingBackdrop>
  );
};

export default Locations;
