import Alert from "@mui/material/Alert";
import MuiSnackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import { hideNotification } from "../store/notificationSlice";

const Snackbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();
  const { type, text, duration } = useAppSelector(
    (state) => state.notification
  );
  const [open, setOpen] = React.useState<boolean>(!!text && !!type);
  useEffect(() => {
    setOpen(!!text && !!type);
  }, [text, type]);
  const handleClose = useCallback(
    (_: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (reason === "clickaway") {
        return;
      }
      dispatch(hideNotification());
      setOpen(false);
    },
    []
  );
  return (
    <MuiSnackbar
      style={{ zIndex: 9999 }}
      open={open}
      autoHideDuration={duration || 3000}
      onClose={(event, reason) => {
        handleClose(event, reason);
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        style={{ alignItems: "center" }}
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={type}
      >
        {text ? getTranslationByLabel(text) : "N/A"}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
