import Typography from "@mui/material/Typography";
import classNames from "classnames";
import React from "react";
import "./style.scss";

type OnwProps = {
  children: React.ReactNode;
  className?: string;
  label: string;
  disabled?: boolean;
};

const OutlineCard = ({ children, label, disabled, className }: OnwProps) => {
  return (
    <div
      className={classNames(
        "outlined-container",
        {
          "disabled-container": disabled,
        },
        className
      )}
    >
      <Typography
        variant="overline"
        className={classNames("legend", { ["disabled"]: disabled })}
        color="primary"
      >
        {label}
      </Typography>
      <div className="children-container">{children}</div>
    </div>
  );
};

export default OutlineCard;
