import { useOktaAuth } from "@okta/okta-react";
import { Redirect } from "react-router-dom";
import { APPLICATION_PATH, LOGIN_PATH } from "../routes";

const Welcome = () => {
  const { authState } = useOktaAuth();

  if (!authState) {
    return <div>Loading ...</div>;
  }

  if (authState?.isAuthenticated) {
    return <Redirect to={{ pathname: APPLICATION_PATH }} />;
  } else {
    return <Redirect to={{ pathname: LOGIN_PATH }} />;
  }
};

export default Welcome;
