import axios, { AxiosRequestHeaders } from "axios";
import { ACCESS_TOKEN_KEY } from "../constants";
import store from "../store";
// import {
//   EnumNotificationType,
//   showNotification,
// } from "../store/notificationSlice";
import { setShouldHardLogout } from "../store/userSlice";

export interface PartialApiItemsResponse {
  totalElements: number;
  totalPages: number;
}

let API_REQUEST_INTERCEPTOR_ID: number;
let API_RESPONSE_INTERCEPTOR_ID: number;

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/api`;
console.log("Base URL => ", axios.defaults.baseURL);

function interceptApiRequest(tokenProp?: string) {
  return new Promise<number>((resolve, reject) => {
    const token = tokenProp ?? localStorage.getItem(ACCESS_TOKEN_KEY);
    const interceptorId = axios.interceptors.request.use((config) => {
      const headers: AxiosRequestHeaders = {
        Authorization: `Bearer ${token}`,
      };
      if (token) {
        if (!config) {
          config = {
            headers,
          };
        } else {
          if (!config.headers) {
            config.headers = headers;
          } else {
            config.headers["Authorization"] = `Bearer ${token}`;
          }
        }
      }

      return config;
    }, reject);
    resolve(interceptorId);
  });
}

function interceptApiResponse() {
  return axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const { response } = error;
      const urlApiIndex = response ? response.config?.url?.indexOf("/api") : 0;
      if (response && urlApiIndex !== 0) {
        if (response.status === 401) {
          store.dispatch(setShouldHardLogout(true));
        } else {
          // store.dispatch(
          //   showNotification({
          //     text:
          //       response?.data?.message ||
          //       "Something went wrong. Please try again later.",
          //     type: EnumNotificationType.Error,
          //   })
          // );
        }
      } else {
        // store.dispatch(
        //   showNotification({
        //     text: "Something went wrong. Please try again later.",
        //     type: EnumNotificationType.Error,
        //   })
        // );
      }
      return Promise.reject(error);
    }
  );
}

export const setInterceptApiRequest = function (token?: string) {
  if (API_REQUEST_INTERCEPTOR_ID === undefined) {
    return new Promise<void>((resolve, reject) => {
      interceptApiRequest(token)
        .then((interceptorId) => {
          API_REQUEST_INTERCEPTOR_ID = interceptorId;
          resolve();
        })
        .catch(reject);
    });
  }
  return Promise.resolve();
};

export const setInterceptApiResponse = function () {
  if (API_RESPONSE_INTERCEPTOR_ID === undefined) {
    API_RESPONSE_INTERCEPTOR_ID = interceptApiResponse();
  }
};

export const cleanAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const setSignupSuccess = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
};
