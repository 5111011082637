import { Box, Button, Chip, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import "./style.scss";

const FILTER_KEY = "filter-by";

export interface FilterItem {
  key: string;
  label: string;
}

interface IFilterBar {
  label: string;
  filterKey?: string;
  singleFilter?: boolean;
  items?: FilterItem[];
  withButton?: boolean;
  secondaryButton?: React.ReactNode;
  onClick?: () => void;
}

const FilterBar = ({
  label,
  items,
  filterKey = FILTER_KEY,
  withButton = false,
  singleFilter = false,
  secondaryButton,
  onClick,
}: IFilterBar) => {
  const { t: getTranslationByLabel } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const urlSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const isActive = useCallback(
    (key: string) => {
      const filters = urlSearchParams.getAll(filterKey);
      return filters.includes(key);
    },
    [urlSearchParams]
  );

  const handleChipClick = useCallback(
    (key: string) => {
      if (key === "all") {
        history.replace({
          pathname: location.pathname,
          search: "",
        });
      } else {
        let filters = urlSearchParams.getAll(filterKey);
        if (filters.includes(key)) {
          filters = filters.filter((item) => item !== key);
        } else {
          if (singleFilter) {
            filters = [key];
          } else {
            filters.push(key);
          }
        }
        const newUrlSearchParams = new URLSearchParams();
        filters.forEach((el) => newUrlSearchParams.append(filterKey, el));
        history.replace({
          pathname: location.pathname,
          search: newUrlSearchParams.toString(),
        });
      }
    },
    [location, history, urlSearchParams, singleFilter]
  );
  return (
    <div className="filter-bar-container">
      <div className="filter-container">
        <Typography
          variant="body1"
          align="left"
          color="primary"
          className="label"
        >
          {label}
        </Typography>
        {items && !!items.length && (
          <Box display="flex" alignItems="center" ml={3} flexWrap="wrap">
            <Typography
              variant="body1"
              align="left"
              color="primary"
              className="label"
            >
              {getTranslationByLabel("filter")}:
            </Typography>
            {items.map((item) => (
              <Chip
                key={item.key}
                className="chip"
                label={item.label}
                variant={isActive(item.key) ? "filled" : "outlined"}
                color="primary"
                onClick={() => {
                  handleChipClick(item.key);
                }}
              />
            ))}
          </Box>
        )}
      </div>
      {secondaryButton}
      {withButton && onClick && (
        <Button variant="contained" className="button" onClick={onClick}>
          {getTranslationByLabel("create-new")}
        </Button>
      )}
    </div>
  );
};

export default FilterBar;
