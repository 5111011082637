import { useOktaAuth } from "@okta/okta-react";
import { Redirect } from "react-router-dom";
import SigninForm from "../components/SigninForm";
import { useAppDispatch, useAppSelector } from "../hooks";
import { APPLICATION_PATH } from "../routes";
import { getSelfUser, selectSelfUser } from "../store/userSlice";

const Login = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useAppDispatch();
  const selfUser = useAppSelector(selectSelfUser);

  if (!authState) {
    return <div>Loading ... </div>;
  }

  if (authState && authState.isAuthenticated) {
    const accessToken = oktaAuth.getAccessToken();
    if (accessToken) {
      dispatch(getSelfUser(accessToken));
    }
  }

  return selfUser ? (
    <Redirect to={{ pathname: APPLICATION_PATH }} />
  ) : (
    <SigninForm />
  );
};

export default Login;
