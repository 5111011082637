import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import { Field, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FormikTextField from "../../common/FormikTextField";
import WithLoadingBackdrop from "../../common/WithLoadingBackdrop";
import { useAppDispatch } from "../../hooks";
import { FORGOT_PASSWORD_PATH } from "../../routes";
import { clearState } from "../../store/userSlice";
import { getLoginValidationSchema } from "../../validation";
import "./style.scss";

interface LoginFormikValues {
  email: string;
  password: string;
  showPassword: boolean;
  submitError: string;
}

const CustomSigninForm = () => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();
  const [sessionToken, setSessionToken] = useState<string>();

  useEffect(() => {
    dispatch(clearState());
  }, []);

  const initialValues = useMemo<LoginFormikValues>(
    () => ({
      email: "",
      password: "",
      showPassword: false,
      submitError: "",
    }),
    []
  );

  const handleSubmit = useCallback(
    (
      { email, password }: LoginFormikValues,
      { setSubmitting, setFieldValue }: FormikHelpers<LoginFormikValues>
    ) => {
      setSubmitting(true);
      setFieldValue("submitError", "");
      oktaAuth
        .signInWithCredentials({ username: email, password })
        .then((res) => {
          const sessionToken = res.sessionToken;
          setSessionToken(sessionToken);
          // sessionToken is a one-use token, so make sure this is only called once
          setSubmitting(false);
          oktaAuth.signInWithRedirect({ sessionToken });
        })
        .catch((err) => {
          console.log("Found an error", JSON.stringify(err));
          setFieldValue("submitError", err.message);
          setSubmitting(false);
        });
    },
    [oktaAuth]
  );

  return (
    <div className="signin-form-container">
      <Formik
        initialValues={initialValues}
        validationSchema={getLoginValidationSchema(
          getTranslationByLabel("invalid-email"),
          getTranslationByLabel("please-enter-email"),
          getTranslationByLabel("please-enter-password")
        )}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values: { email, password, showPassword, submitError },
          setFieldValue,
          submitForm,
        }) => (
          <WithLoadingBackdrop open={isSubmitting}>
            <div className="form">
              <Typography className="login-label" variant="h5" color="primary">
                {getTranslationByLabel("log-in")}
              </Typography>
              <Field
                component={FormikTextField}
                className="input"
                name="email"
                label={getTranslationByLabel("enter-email")}
                fullWidth
                InputProps={{
                  inputProps: {
                    type: "email",
                  },
                }}
              />
              <Field
                component={FormikTextField}
                className="input"
                name="password"
                label={getTranslationByLabel("enter-password")}
                fullWidth
                InputProps={{
                  inputProps: {
                    type: showPassword ? "text" : "password",
                  },
                  endAdornment: password ? (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setFieldValue("showPassword", !showPassword);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                        />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
              <Button
                className="button"
                onClick={submitForm}
                variant="contained"
                color="primary"
                disabled={isSubmitting || !email || !password}
              >
                {getTranslationByLabel("sign-in")}
              </Button>
              {submitError && (
                <Typography variant="caption" color="error">
                  {submitError}
                </Typography>
              )}
              <Link to={FORGOT_PASSWORD_PATH}>
                <Typography
                  className="forgot-password"
                  variant="overline"
                  color="primary"
                >
                  {getTranslationByLabel("forgot-password")}
                </Typography>
              </Link>
            </div>
          </WithLoadingBackdrop>
        )}
      </Formik>
    </div>
  );
};
export default CustomSigninForm;
