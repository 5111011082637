import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Bin } from "../../entities";
import { parseBinSizeKey } from "../../entities/Bin";

interface OwnProps {
  item: Partial<Bin.Type>;
  onEdit: () => void;
}

const BinItem = ({
  item: { binDoor, isHandicap, active, number },
  onEdit,
}: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  return (
    <Box
      display="flex"
      alignItems="center"
      className="bin-container"
      width="100%"
      py={3}
      mb={1}
    >
      <Box display="flex" flex={2} justifyContent="space-between">
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          <Typography textAlign="center" color="primary">
            {number}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography
            component="p"
            textAlign="left"
            textTransform="uppercase"
            variant="caption"
            color="primary"
          >
            {getTranslationByLabel("size")}
          </Typography>
          <Typography
            className="description-label"
            textAlign="left"
            variant="h6"
            color="primary"
          >
            {binDoor
              ? getTranslationByLabel(parseBinSizeKey(binDoor.size))
              : "N/A"}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography
            component="p"
            textAlign="left"
            textTransform="uppercase"
            variant="caption"
            color="primary"
          >
            {getTranslationByLabel("status")}
          </Typography>
          <div
            className={classNames(
              active ? "active" : "inactive",
              "status-item"
            )}
          >
            <Typography
              textAlign="left"
              component="p"
              variant="caption"
              color={active ? "white" : "primary"}
            >
              {getTranslationByLabel(active ? "active" : "inactive")}
            </Typography>
          </div>
        </Box>
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography
            component="p"
            textAlign="left"
            textTransform="uppercase"
            variant="caption"
            color="primary"
          >
            {getTranslationByLabel("handicap-ready")}
          </Typography>
          <Typography
            component="p"
            textAlign="left"
            textTransform="capitalize"
            variant="caption"
            color="primary"
          >
            {getTranslationByLabel(isHandicap ? "yes" : "no")}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flex={1} justifyContent="flex-end" mr={2}>
        <Button
          className={classNames("small", "button")}
          variant="outlined"
          fullWidth
          onClick={onEdit}
        >
          {getTranslationByLabel("edit")}
        </Button>
      </Box>
    </Box>
  );
};

export default BinItem;
