import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "../../common/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormikHelpers, useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteLocation,
  submitLocation,
  SubmitLocationType,
} from "../../api/locations";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCreateLocationValidationSchema } from "../../validation";
import "./style.scss";
import {
  EnumNotificationType,
  showNotification,
} from "../../store/notificationSlice";

const NUMBER_REGEX = /^\d+$/;

interface FormikValues extends SubmitLocationType {}

interface OwnProps {
  id?: string;
  open: boolean;
  onClose: (shouldRefetch?: boolean) => void;
}

const LocationDialog = ({ id, open, onClose }: OwnProps) => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();
  const location = useAppSelector((state) =>
    id ? state.locations.items[id] : null
  );
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    if (location) {
      resetForm({
        values: {
          ...location,
          companyName: location.companyName ?? "",
          country: location.country || getTranslationByLabel("france"),
          floor: String(location.floor),
          locationFeature: location.locationFeature ?? {
            lockerFeatureEnabled: false,
            notificationFeatureEnabled: false,
            smsFeatureEnabled: false,
          },
        },
      });
    }
  }, [location]);

  useEffect(() => {
    if (!open) {
      resetForm({ values: initialFormikValues });
    }
  }, [open]);

  const COUNTRIES = useMemo(
    () => [
      getTranslationByLabel("france"),
      getTranslationByLabel("italy"),
      getTranslationByLabel("spain"),
      getTranslationByLabel("germany"),
      getTranslationByLabel("austria"),
      getTranslationByLabel("the-netherlands"),
      getTranslationByLabel("belgium"),
      getTranslationByLabel("united-kingdom"),
      getTranslationByLabel("ireland"),
      getTranslationByLabel("canada"),
      getTranslationByLabel("united-states"),
      getTranslationByLabel("switzerland"),
      getTranslationByLabel("bulgaria"),
    ],
    []
  );

  const initialFormikValues = useMemo<FormikValues>(
    () => ({
      latitude: undefined,
      longitude: undefined,
      city: "",
      country: getTranslationByLabel("france"),
      address: "",
      building: "",
      floor: "",
      phoneNumber: "",
      name: "",
      image: "",
      organization: "",
      companyName: "",
      locationFeature: {
        lockerFeatureEnabled: false,
        notificationFeatureEnabled: false,
        smsFeatureEnabled: false,
      },
    }),
    []
  );

  const {
    isSubmitting,
    values,
    errors,
    touched,
    dirty,
    resetForm,
    setSubmitting,
    setFieldTouched,
    submitForm,
    handleChange,
  } = useFormik<FormikValues>({
    initialValues: initialFormikValues,
    validationSchema: getCreateLocationValidationSchema(
      getTranslationByLabel("required-field"),
      getTranslationByLabel("invalid-phone")
    ),
    onSubmit: async (
      values: FormikValues,
      { setSubmitting }: FormikHelpers<FormikValues>
    ) => {
      try {
        setSubmitting(true);
        if (
          !values?.locationFeature.lockerFeatureEnabled &&
          !values?.locationFeature.notificationFeatureEnabled
        ) {
          dispatch(
            showNotification({
              text: getTranslationByLabel("locker-or-notification-feature"),
              type: EnumNotificationType.Error,
            })
          );
          setSubmitting(false);
          return;
        }
        await submitLocation(values, !id);
        setSubmitting(false);
        onClose(true);
      } catch (error: any) {
        setSubmitting(false);
        dispatch(
          showNotification({
            text: error.response.data,
            type: EnumNotificationType.Error,
          })
        );
        console.log("Error while save location -> ", error);
      }
    },
  });

  const handleDelete = useCallback(async () => {
    if (!id) {
      return;
    }
    setSubmitting(true);
    await deleteLocation(id);
    setSubmitting(false);
    setDeleteDialogOpen(false);
    onClose(true);
  }, [id, setSubmitting]);

  const handleClose = useCallback(() => {
    resetForm({
      values: initialFormikValues,
    });
    onClose();
  }, [initialFormikValues, resetForm, onClose]);

  const hasAddressError = useMemo(() => {
    return (
      (!!touched.address && Boolean(errors.address)) ||
      (!!touched.building && Boolean(errors.building)) ||
      (!!touched.floor && Boolean(errors.floor)) ||
      (!!touched.city && Boolean(errors.city))
    );
  }, [touched, errors]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        className="location-dialog"
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">
              {getTranslationByLabel(
                id ? "update-location" : "create-location"
              )}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              color="primary"
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ overflow: "hidden", overflowY: "auto" }}>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box flex={3}>
                <Typography
                  textAlign="left"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  {getTranslationByLabel("location-details")}
                </Typography>
              </Box>
              <Box flex={1}>
                <Typography
                  textAlign="left"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  {getTranslationByLabel("features")}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box display="flex" flex={3}>
                <Box display="flex" flex={1} mr={2} width="100%">
                  <InputItem label={getTranslationByLabel("image")}>
                    <IconButton
                      className="image-button"
                      onClick={() => {
                        setImageDialogOpen(true);
                      }}
                    >
                      {values.image ? (
                        <img className="image" src={values.image} />
                      ) : (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          className="image"
                        >
                          <FontAwesomeIcon icon={faPlus} size="3x" />
                        </Box>
                      )}
                    </IconButton>
                    {!!touched.image && Boolean(errors.image) && (
                      <FormHelperText
                        className="helper-text"
                        error={!!touched.image && Boolean(errors.image)}
                      >
                        {errors.image}
                      </FormHelperText>
                    )}
                  </InputItem>
                </Box>
                <Box display="flex" flexDirection="column" mr={2}>
                  <Box display="flex">
                    <Box display="flex" flex={1} mr={2}>
                      <InputItem label={getTranslationByLabel("address")}>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            InputProps={{
                              className: "top-input",
                            }}
                            fullWidth
                            id="address"
                            name="address"
                            type="text"
                            placeholder={getTranslationByLabel("enter-address")}
                            value={values.address}
                            onChange={(e) => {
                              setFieldTouched(e.target.name, true);
                              handleChange(e);
                            }}
                          />
                          <Box display="flex">
                            <TextField
                              InputProps={{
                                className: "left-input",
                              }}
                              fullWidth
                              id="building"
                              name="building"
                              type="text"
                              placeholder={getTranslationByLabel(
                                "enter-building"
                              )}
                              value={values.building}
                              onChange={(e) => {
                                setFieldTouched(e.target.name, true);
                                handleChange(e);
                              }}
                            />
                            <TextField
                              fullWidth
                              InputProps={{
                                className: "right-input",
                              }}
                              id="floor"
                              name="floor"
                              type="text"
                              placeholder={getTranslationByLabel("enter-floor")}
                              value={String(values.floor)}
                              onChange={(e) => {
                                if (NUMBER_REGEX.test(e.target.value)) {
                                  setFieldTouched(e.target.name, true);
                                  handleChange(e);
                                }
                              }}
                            />
                          </Box>
                          <Box display="flex">
                            <TextField
                              InputProps={{
                                className: "left-input bottom-input",
                              }}
                              fullWidth
                              id="city"
                              name="city"
                              type="text"
                              placeholder={getTranslationByLabel("enter-city")}
                              value={values.city}
                              onChange={(e) => {
                                setFieldTouched(e.target.name, true);
                                handleChange(e);
                              }}
                            />
                          </Box>
                        </Box>
                        <FormHelperText
                          error={hasAddressError}
                          className="helper-text"
                        >
                          {hasAddressError
                            ? getTranslationByLabel("required-address")
                            : " "}
                        </FormHelperText>
                      </InputItem>
                    </Box>
                    <Box
                      display="flex"
                      flex={1}
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <InputItem label={getTranslationByLabel("location-name")}>
                        <TextField
                          fullWidth
                          id="name"
                          name="name"
                          type="text"
                          placeholder={`${getTranslationByLabel(
                            "enter-location-name"
                          )}...`}
                          value={values.name}
                          onChange={(e) => {
                            setFieldTouched(e.target.name, true);
                            handleChange(e);
                          }}
                          error={touched.name && Boolean(errors.name)}
                          helperText={(touched.name && errors.name) || " "}
                        />
                      </InputItem>
                      <InputItem label={getTranslationByLabel("company")}>
                        <TextField
                          fullWidth
                          id="companyName"
                          name="companyName"
                          type="text"
                          placeholder={`${getTranslationByLabel(
                            "enter-company-name"
                          )}...`}
                          value={values.companyName}
                          onChange={(e) => {
                            setFieldTouched(e.target.name, true);
                            handleChange(e);
                          }}
                          error={
                            touched.companyName && Boolean(errors.companyName)
                          }
                          helperText={
                            (touched.companyName && errors.companyName) || " "
                          }
                        />
                      </InputItem>
                    </Box>
                  </Box>
                  <Box display="flex" flex={2} width="100%">
                    <InputItem
                      className="country-input"
                      label={getTranslationByLabel("country")}
                    >
                      <FormControl error={!!errors.country && touched.country}>
                        <Select
                          fullWidth
                          name="country"
                          value={values.country}
                          displayEmpty
                          placeholder={`${getTranslationByLabel(
                            "select-country"
                          )}...`}
                          onChange={(e) => {
                            setFieldTouched(e.target.name, true);
                            handleChange(e);
                          }}
                        >
                          {COUNTRIES.map((el) => (
                            <MenuItem
                              key={el}
                              value={el}
                              disabled={el === values.country}
                            >
                              {el}
                            </MenuItem>
                          ))}
                        </Select>
                        {Boolean(errors.country) && touched.country && (
                          <FormHelperText>{errors.country}</FormHelperText>
                        )}
                      </FormControl>
                    </InputItem>
                    <InputItem label={getTranslationByLabel("phone-number")}>
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        placeholder={`${getTranslationByLabel(
                          "enter-phone-number"
                        )}...`}
                        onChange={(e) => {
                          if (e.target.value.length > 15) {
                            return;
                          }
                          if (!e.target.value) {
                            e.target.value = "+";
                          }
                          if (
                            e.target.value.length === 1 &&
                            e.target.value !== "+"
                          ) {
                            e.target.value = `+${e.target.value}`;
                          }
                          if (
                            NUMBER_REGEX.test(
                              e.target.value.substring(1, e.target.value.length)
                            ) ||
                            e.target.value === "+"
                          ) {
                            setFieldTouched(e.target.name, true);
                            handleChange(e);
                          }
                        }}
                        error={
                          touched.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        helperText={
                          (touched.phoneNumber && errors.phoneNumber) || " "
                        }
                      />
                    </InputItem>
                  </Box>
                  <Box display="flex" flex={2} width="100%">
                    <InputItem
                      label={getTranslationByLabel("latitude")}
                      className="country-input"
                    >
                      <TextField
                        fullWidth
                        id="latitude"
                        name="latitude"
                        value={!values.latitude ? "" : String(values.latitude)}
                        placeholder={`${getTranslationByLabel(
                          "enter-latitude"
                        )}...`}
                        onChange={(e) => {
                          setFieldTouched(e.target.name, true);
                          handleChange(e);
                        }}
                        error={touched.latitude && Boolean(errors.latitude)}
                        helperText={
                          (touched.latitude && errors.latitude) || " "
                        }
                      />
                    </InputItem>
                    <InputItem label={getTranslationByLabel("longitude")}>
                      <TextField
                        fullWidth
                        id="longitude"
                        name="longitude"
                        value={
                          !values.longitude ? "" : String(values.longitude)
                        }
                        placeholder={`${getTranslationByLabel(
                          "enter-longitude"
                        )}...`}
                        onChange={(e) => {
                          setFieldTouched(e.target.name, true);
                          handleChange(e);
                        }}
                        error={touched.longitude && Boolean(errors.longitude)}
                        helperText={
                          (touched.longitude && errors.longitude) || " "
                        }
                      />
                    </InputItem>
                  </Box>
                  <InputItem label={getTranslationByLabel("information")}>
                    <TextField
                      fullWidth
                      id="information"
                      name="information"
                      value={values.information}
                      placeholder={`${getTranslationByLabel(
                        "enter-information"
                      )}...`}
                      onChange={(e) => {
                        setFieldTouched(e.target.name, true);
                        handleChange(e);
                      }}
                      error={touched.information && Boolean(errors.information)}
                      helperText={
                        (touched.information && errors.information) || " "
                      }
                    />
                  </InputItem>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onChange={(e, checked) => {
                        handleChange({
                          target: {
                            name: e.target.name,
                            value: checked,
                          },
                        });
                      }}
                      name="locationFeature.lockerFeatureEnabled"
                      checked={values.locationFeature.lockerFeatureEnabled}
                    />
                  }
                  label={getTranslationByLabel("locker-feature-enabled")}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      name="locationFeature.notificationFeatureEnabled"
                      checked={
                        values.locationFeature.notificationFeatureEnabled
                      }
                      onChange={(e, checked) => {
                        handleChange({
                          target: {
                            name: e.target.name,
                            value: checked,
                          },
                        });
                      }}
                    />
                  }
                  label={getTranslationByLabel("notification-feature-enabled")}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      name="locationFeature.smsFeatureEnabled"
                      checked={values.locationFeature.smsFeatureEnabled}
                      onChange={(e, checked) => {
                        handleChange({
                          target: {
                            name: e.target.name,
                            value: checked,
                          },
                        });
                      }}
                    />
                  }
                  label={getTranslationByLabel("sms-feature-enabled")}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className="actions">
          {!!id && (
            <>
              <Button
                className="button"
                variant="text"
                disabled={isSubmitting}
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              >
                {getTranslationByLabel("delete")}
              </Button>
            </>
          )}
          <Button
            className="button"
            variant="contained"
            autoFocus
            disabled={isSubmitting || !dirty}
            onClick={submitForm}
          >
            {getTranslationByLabel("submit")}
          </Button>
        </DialogActions>
        <ImageDialog
          open={imageDialogOpen}
          value={values.image}
          error={!!touched.image && Boolean(errors.image)}
          onClose={() => {
            setImageDialogOpen(false);
          }}
          onSubmit={(value) => {
            handleChange({
              target: {
                name: "image",
                value,
              },
            });
            setImageDialogOpen(false);
          }}
        />
      </Dialog>
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        // disabled={isSubmitting}
        onConfirm={handleDelete}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};

interface ImageDialogProps {
  open: boolean;
  value?: string;
  error: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
}

const ImageDialog = ({
  open,
  value,
  error,
  onSubmit,
  onClose,
}: ImageDialogProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const [innerValue, setInnerValue] = useState(value ?? "");

  const handleClose = useCallback(() => {
    setInnerValue(value ?? "");
    onClose();
  }, [onClose]);

  const hasError = useMemo(() => error && !innerValue, [error, innerValue]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>{getTranslationByLabel("image-url")}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id="image"
          name="image"
          type="text"
          placeholder={`${getTranslationByLabel("enter-image-url")}...`}
          value={innerValue}
          onChange={(e) => {
            setInnerValue(e.target.value);
          }}
          error={hasError}
          helperText={
            (hasError && getTranslationByLabel("required-field")) || " "
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {getTranslationByLabel("cancel")}
        </Button>
        <Button
          disabled={hasError || !innerValue}
          variant="contained"
          onClick={() => {
            onSubmit(innerValue);
          }}
        >
          {getTranslationByLabel("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
interface InputItemProps {
  label: string;
  className?: string;
}

const InputItem: React.FC<InputItemProps> = ({
  children,
  label,
  className,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      className={className}
    >
      <Typography
        component="p"
        textAlign="left"
        textTransform="uppercase"
        variant="caption"
        color="primary"
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};

export default LocationDialog;
