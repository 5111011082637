import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

interface OwnProps {
  open: boolean;
  // disabled: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const DeleteConfirmationDialog = ({
  open,
  // disabled,
  onConfirm,
  onClose,
}: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">
            {getTranslationByLabel("delete-item-title")}
          </Typography>
          <IconButton aria-label="close" onClick={onClose} color="primary">
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {getTranslationByLabel("delete-item-content")}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ alignItems: "baseline" }}>
        <Button
          // disabled={disabled}
          className="button"
          variant="text"
          onClick={onClose}
        >
          {getTranslationByLabel("cancel")}
        </Button>
        <Button
          // disabled={disabled}
          variant="contained"
          color="error"
          autoFocus
          onClick={onConfirm}
        >
          {getTranslationByLabel("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
