import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Typography } from "@mui/material";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useUserAccess } from "../../hooks/useUserAccess";
import {
  DELIVERY_PATH,
  LOCATIONS_PATH,
  LOCKERS_PATH,
  NOTIFICATIONS_PATH,
  REPORTS_PATH,
  USERS_PATH,
} from "../../routes";
import SettingsPopover from "../SettingsPopover";
import "./style.scss";

interface Item {
  // The label key is presented in translation files
  labelKey: string;
  path: string;
}

interface OwnProps {
  onToggleSettings: () => void;
}

const TopBar = ({ onToggleSettings }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const location = useLocation();
  const userAccess = useUserAccess();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    onToggleSettings();
  };

  const ITEMS = useMemo(() => {
    const {
      hasNotificationsAccess,
      hasServiceOrdersAccess,
      hasLockersAccess,
      hasUsersAccess,
      hasLocationsAccess,
      hasReportsAccess,
    } = userAccess;
    const result: Item[] = [];
    if (hasNotificationsAccess) {
      result.push({
        labelKey: "notifications-menu",
        path: NOTIFICATIONS_PATH,
      });
    }
    if (hasServiceOrdersAccess) {
      result.push({
        labelKey: "delivery-menu",
        path: DELIVERY_PATH,
      });
    }
    if (hasLockersAccess) {
      result.push({
        labelKey: "lockers-menu",
        path: LOCKERS_PATH,
      });
    }
    if (hasUsersAccess) {
      result.push({
        labelKey: "users-menu",
        path: USERS_PATH,
      });
    }
    if (hasLocationsAccess) {
      result.push({
        labelKey: "locations-menu",
        path: LOCATIONS_PATH,
      });
    }
    if (hasReportsAccess) {
      result.push({
        labelKey: "reports-menu",
        path: REPORTS_PATH,
      });
    }
    return result;
  }, [userAccess]);

  const open = Boolean(anchorEl);

  return (
    <div className="top-bar">
      {ITEMS.map((item) => (
        <Link key={item.path} to={item.path}>
          <Typography
            component="span"
            className={classNames("item", {
              selected: location.pathname === item.path,
            })}
          >
            {getTranslationByLabel(item.labelKey)}
          </Typography>
        </Link>
      ))}
      <IconButton
        size="small"
        aria-describedby={open ? "settings-popover" : undefined}
        onClick={handleSettingsClick}
      >
        <FontAwesomeIcon
          icon={faCog}
          className={classNames("item", {
            selected: open,
          })}
        />
      </IconButton>
      <SettingsPopover
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          onToggleSettings();
        }}
      />
    </div>
  );
};

export default TopBar;
