import { useEffect, useState } from "react";
import { useAppSelector } from "../hooks";
import { selectSelfUser } from "../store/userSlice";

interface UserAccess {
  hasServiceOrdersAccess: boolean;
  hasNotificationsAccess: boolean;
  hasLockersAccess: boolean;
  hasUsersAccess: boolean;
  hasLocationsAccess: boolean;
  hasReportsAccess: boolean;
}

export function useUserAccess() {
  const [userAccess, setUserAccess] = useState<UserAccess>({
    hasServiceOrdersAccess: false,
    hasNotificationsAccess: false,
    hasLockersAccess: false,
    hasUsersAccess: false,
    hasLocationsAccess: false,
    hasReportsAccess: false,
  });
  const selfUser = useAppSelector(selectSelfUser);

  useEffect(() => {
    if (selfUser) {
      const hasNotificationsAccess =
        selfUser.role === "MARVELOCK_ADMIN" ||
        selfUser.role === "SITE_MANAGER" ||
        selfUser.role === "BUILDING_MANAGER" ||
        (selfUser.role === "RESIDENT" && selfUser.notificationFeatureEnabled);
      const hasServiceOrdersAccess =
        selfUser.role === "MARVELOCK_ADMIN" ||
        selfUser.role === "SITE_MANAGER" ||
        (selfUser.role === "RESIDENT" && selfUser.lockerFeatureEnabled);
      const hasUsersAccess =
        selfUser.role === "MARVELOCK_ADMIN" ||
        selfUser.role === "SITE_MANAGER" ||
        selfUser.role === "BUILDING_MANAGER";
      // Users different from MARVELOCK_ADMIN can only see the lockers
      const hasLockersAccess = selfUser.role === "MARVELOCK_ADMIN";
      // Users different from MARVELOCK_ADMIN can only see the locations
      const hasLocationsAccess = selfUser.role === "MARVELOCK_ADMIN";
      const hasReportsAccess = selfUser.role === "MARVELOCK_ADMIN";
      setUserAccess({
        hasNotificationsAccess,
        hasServiceOrdersAccess,
        hasLocationsAccess,
        hasLockersAccess,
        hasUsersAccess,
        hasReportsAccess,
      });
    }
  }, [selfUser]);

  return userAccess;
}
