import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { FormikHelpers, useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchLocations } from "../../api/locations";
import { deleteUser, submitUser, SubmitUserType } from "../../api/user";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";
import Select from "../../common/Select";
import { Location, User } from "../../entities";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  EnumNotificationType,
  showNotification,
} from "../../store/notificationSlice";
import { selectSelfUser } from "../../store/userSlice";
import { getCreateUserValidationSchema, NUMBER_REGEX } from "../../validation";
import "./style.scss";

const LOCATION_ITEMS_PER_PAGE = 6;

interface FormikValues extends SubmitUserType {}

interface OwnProps {
  id?: string;
  open: boolean;
  onClose: (shouldRefetch?: boolean) => void;
}

const UserDialog = ({ id, open, onClose }: OwnProps) => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();
  const [availableLocations, setAvailableLocations] = useState<Location.Type[]>(
    []
  );
  const [locationsCount, setLocationsCount] = useState(0);
  const [availableLocationsPage, setAvailableLocationsPage] = useState(1);
  const [profileLocationsPage, setProfileLocationsPage] = useState(1);
  const user = useAppSelector((state) => (id ? state.user.items[id] : null));
  const selfUser = useAppSelector(selectSelfUser);
  const [availableTypes, setAvailableTypes] = useState<User.UserType[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    if (open) {
      axios
        .get<User.UserType[]>("/v2/users/available-roles")
        .then((response) => {
          setAvailableTypes(response.data);
        });
    }
  }, [open]);

  useEffect(() => {
    if (user && selfUser) {
      const selfUserLocations = selfUser.userLocations.map((el) => el.id);
      let userLocations = user.locations;
      if (selfUser.role !== "MARVELOCK_ADMIN") {
        userLocations = user.locations.filter((location) =>
          selfUserLocations.includes(location.id)
        );
      }
      resetForm({
        values: {
          ...user,
          locations: userLocations,
        },
      });
    }
  }, [user, selfUser]);

  useEffect(() => {
    if (!open) {
      resetForm({ values: initialFormikValues });
      setAvailableLocations([]);
      setLocationsCount(1);
      setAvailableLocationsPage(1);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      getLocations();
    }
  }, [open, availableLocationsPage]);

  const getLocations = useCallback(async () => {
    const locationResponse = await fetchLocations(
      availableLocationsPage - 1,
      LOCATION_ITEMS_PER_PAGE
    );
    setAvailableLocations(locationResponse.content);
    setLocationsCount(locationResponse.totalElements);
  }, [availableLocationsPage]);

  const initialFormikValues = useMemo<FormikValues>(
    () => ({
      firstName: "",
      lastName: "",
      middleName: "",
      badgeId: undefined,
      email: "",
      phone: "",
      userConfiguration: {
        language: "en",
        lockerEmailNotificationsEnabled: false,
        lockerAppNotificationsEnabled: false,
        smsLockerNotificationsEnabled: false,
        emailNotificationsEnabled: false,
        appNotificationsEnabled: false,
        smsNotificationsEnabled: false,
      },
      isHandicap: false,
      isEnabled: false,
      locations: [],
    }),
    []
  );

  const {
    isSubmitting,
    values,
    errors,
    touched,
    dirty,
    resetForm,
    setSubmitting,
    setFieldValue,
    setFieldTouched,
    submitForm,
    handleChange,
  } = useFormik<FormikValues>({
    initialValues: initialFormikValues,
    validationSchema: getCreateUserValidationSchema(
      getTranslationByLabel("required-field"),
      getTranslationByLabel("invalid-email"),
      getTranslationByLabel("invalid-phone"),
      getTranslationByLabel("long-string"),
      getTranslationByLabel("long-bange-id")
    ),
    onSubmit: (
      values: FormikValues,
      { setSubmitting }: FormikHelpers<FormikValues>
    ) => {
      setSubmitting(true);
      const item: SubmitUserType = {
        ...values,
        userConfiguration: {
          ...values.userConfiguration,
          lockerAppNotificationsEnabled:
            isLockerFeatureEnabled &&
            values.userConfiguration?.lockerAppNotificationsEnabled,
          lockerEmailNotificationsEnabled:
            isLockerFeatureEnabled &&
            values.userConfiguration?.lockerEmailNotificationsEnabled,
          smsLockerNotificationsEnabled:
            isLockerFeatureEnabled &&
            values.userConfiguration?.smsLockerNotificationsEnabled,
          appNotificationsEnabled:
            isNotificationFeatureEnabled &&
            values.userConfiguration?.appNotificationsEnabled,
          smsNotificationsEnabled:
            isNotificationFeatureEnabled &&
            values.userConfiguration?.smsNotificationsEnabled,
          emailNotificationsEnabled:
            isNotificationFeatureEnabled &&
            values.userConfiguration?.emailNotificationsEnabled,
        },
      };
      submitUser(item, !id, selfUser?.role === "MARVELOCK_ADMIN")
        .then(() => {
          setSubmitting(false);
          onClose(true);
        })
        .catch((error) => {
          dispatch(
            showNotification({
              text: error.response.data,
              type: EnumNotificationType.Error,
            })
          );
          setSubmitting(false);
          console.log("Error while save user -> ", error);
        });
    },
  });

  const profileLocationsToDisplay = useMemo(() => {
    if (!values.locations.length) {
      return [];
    }
    let startIndex = profileLocationsPage - 1;
    if (profileLocationsPage !== 1) {
      startIndex = LOCATION_ITEMS_PER_PAGE * (profileLocationsPage - 1);
    }
    return values.locations.slice(
      startIndex,
      LOCATION_ITEMS_PER_PAGE * profileLocationsPage
    );
  }, [values.locations, profileLocationsPage]);

  const onAddLocation = useCallback(
    (id: string) => {
      const locationToAdd = availableLocations.find((el) => el.id === id);
      handleChange({
        target: {
          name: "locations",
          value: [...values.locations, locationToAdd],
        },
      });
    },
    [values.locations, availableLocations]
  );

  const onRemoveLocation = useCallback(
    (id: string) => {
      const newLocations = values.locations.filter((el) => el.id !== id);
      handleChange({
        target: {
          name: "locations",
          value: newLocations,
        },
      });
    },
    [values.locations, availableLocations]
  );

  const handleDelete = useCallback(async () => {
    if (!id) {
      return;
    }
    setSubmitting(true);
    try {
      await deleteUser(id);
    } catch (error: any) {
      dispatch(
        showNotification({
          text: error.response.data,
          type: EnumNotificationType.Error,
        })
      );
      console.log("Error while deleting a user -> ", error);
    }
    setSubmitting(false);
    setDeleteDialogOpen(false);
    onClose(true);
  }, [id, setSubmitting]);

  const handleClose = useCallback(() => {
    resetForm({
      values: initialFormikValues,
    });
    onClose();
  }, [initialFormikValues, resetForm, onClose]);

  const languageDisplayedValue = useMemo(() => {
    if (values.userConfiguration) {
      if (values.userConfiguration.language === "en") {
        return getTranslationByLabel("english");
      }
      return getTranslationByLabel("french");
    }
    return "";
  }, [values.userConfiguration]);

  const isLockerFeatureEnabled = useMemo(() => {
    if (values.lockerFeatureEnabled) {
      return true;
    } else {
      return values.locations.some(
        (el) => el.locationFeature.lockerFeatureEnabled
      );
    }
  }, [values]);

  const isNotificationFeatureEnabled = useMemo(() => {
    if (values.notificationFeatureEnabled) {
      return true;
    } else {
      return values.locations.some(
        (el) => el.locationFeature.notificationFeatureEnabled
      );
    }
  }, [values]);

  useEffect(() => {
    if (!isLockerFeatureEnabled) {
      setFieldValue("userConfiguration.lockerAppNotificationsEnabled", false);
      setFieldValue("userConfiguration.lockerEmailNotificationsEnabled", false);
      setFieldValue("userConfiguration.smsLockerNotificationsEnabled", false);
    } else if (user) {
      setFieldValue(
        "userConfiguration.lockerAppNotificationsEnabled",
        user.userConfiguration?.lockerAppNotificationsEnabled
      );
      setFieldValue(
        "userConfiguration.lockerEmailNotificationsEnabled",
        user.userConfiguration?.lockerEmailNotificationsEnabled
      );
      setFieldValue(
        "userConfiguration.smsLockerNotificationsEnabled",
        user.userConfiguration?.smsLockerNotificationsEnabled
      );
    }
  }, [user, isLockerFeatureEnabled]);
  useEffect(() => {
    if (!isNotificationFeatureEnabled) {
      setFieldValue("userConfiguration.appNotificationsEnabled", false);
      setFieldValue("userConfiguration.smsNotificationsEnabled", false);
      setFieldValue("userConfiguration.emailNotificationsEnabled", false);
    } else if (user) {
      setFieldValue(
        "userConfiguration.appNotificationsEnabled",
        user.userConfiguration?.appNotificationsEnabled
      );
      setFieldValue(
        "userConfiguration.smsNotificationsEnabled",
        user.userConfiguration?.smsNotificationsEnabled
      );
      setFieldValue(
        "userConfiguration.emailNotificationsEnabled",
        user.userConfiguration?.emailNotificationsEnabled
      );
    }
  }, [user, isNotificationFeatureEnabled]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        className="user-dialog"
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">
              {getTranslationByLabel(id ? "update-user" : "create-user")}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              color="primary"
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ overflow: "hidden", overflowY: "auto" }}>
          <Box display="flex" flexDirection="column">
            <Typography
              textAlign="left"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {getTranslationByLabel("personal-details")}
            </Typography>
            <Box display="flex">
              <InputItem
                className="large-input"
                label={getTranslationByLabel("first-name")}
              >
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder={`${getTranslationByLabel(
                    "enter-first-name"
                  )}...`}
                  value={values.firstName}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={(touched.firstName && errors.firstName) || " "}
                />
              </InputItem>
              <InputItem
                className="large-input"
                label={getTranslationByLabel("middle-name")}
              >
                <TextField
                  fullWidth
                  id="middleName"
                  name="middleName"
                  type="text"
                  placeholder={`${getTranslationByLabel(
                    "enter-middle-name"
                  )}...`}
                  value={values.middleName}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.middleName && Boolean(errors.middleName)}
                  helperText={(touched.middleName && errors.middleName) || " "}
                />
              </InputItem>
              <InputItem
                className="large-input"
                label={getTranslationByLabel("last-name")}
              >
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder={`${getTranslationByLabel("enter-last-name")}...`}
                  value={values.lastName}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={(touched.lastName && errors.lastName) || " "}
                />
              </InputItem>
              <InputItem
                className="large-input"
                label={getTranslationByLabel("role")}
              >
                {!!id && selfUser?.role !== "MARVELOCK_ADMIN" ? (
                  <Box display="flex" alignItems="center" height={"56px"}>
                    <Typography>
                      {values.type ? getTranslationByLabel(values.type) : "N/A"}
                    </Typography>
                  </Box>
                ) : (
                  <FormControl error={!!errors.type && touched.type}>
                    <Select
                      fullWidth
                      name="type"
                      value={
                        values.type ? getTranslationByLabel(values.type) : ""
                      }
                      displayEmpty
                      placeholder={`${getTranslationByLabel("select-role")}...`}
                      onChange={(e) => {
                        setFieldTouched(e.target.name, true);
                        handleChange(e);
                      }}
                    >
                      {availableTypes.map((type) => (
                        <MenuItem
                          key={type}
                          value={type}
                          disabled={values.type === type}
                        >
                          {getTranslationByLabel(type)}
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(errors.type) && touched.type && (
                      <FormHelperText>{errors.type}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </InputItem>
            </Box>
            <Typography
              textAlign="left"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {getTranslationByLabel("contact-information")}
            </Typography>
            <Box display="flex">
              <InputItem
                className="large-input"
                label={getTranslationByLabel("email")}
              >
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  placeholder={`${getTranslationByLabel("enter-email")}...`}
                  value={values.email}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.email && Boolean(errors.email)}
                  helperText={(touched.email && errors.email) || " "}
                />
              </InputItem>
              <InputItem
                className="large-input"
                label={getTranslationByLabel("phone-number")}
              >
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  value={values.phone}
                  placeholder={`${getTranslationByLabel(
                    "enter-phone-number"
                  )}...`}
                  onChange={(e) => {
                    if (e.target.value.length > 15) {
                      return;
                    }
                    if (!e.target.value) {
                      e.target.value = "+";
                    }
                    if (e.target.value.length === 1 && e.target.value !== "+") {
                      e.target.value = `+${e.target.value}`;
                    }
                    if (
                      NUMBER_REGEX.test(
                        e.target.value.substring(1, e.target.value.length)
                      ) ||
                      e.target.value === "+"
                    ) {
                      setFieldTouched(e.target.name, true);
                      handleChange(e);
                    }
                  }}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={(touched.phone && errors.phone) || " "}
                />
              </InputItem>
              <InputItem
                className="small-input"
                label={getTranslationByLabel("language")}
              >
                <FormControl error={Boolean(errors.userConfiguration)}>
                  <Select
                    fullWidth
                    name="userConfiguration.language"
                    value={languageDisplayedValue}
                    displayEmpty
                    placeholder={`${getTranslationByLabel(
                      "select-language"
                    )}...`}
                    onChange={handleChange}
                  >
                    <MenuItem
                      key={"fr"}
                      value={"fr"}
                      disabled={values.userConfiguration?.language === "fr"}
                    >
                      {getTranslationByLabel("french")}
                    </MenuItem>
                    <MenuItem
                      key="en"
                      value="en"
                      disabled={values.userConfiguration?.language === "en"}
                    >
                      {getTranslationByLabel("english")}
                    </MenuItem>
                  </Select>
                  {Boolean(errors.userConfiguration) && (
                    <FormHelperText>{errors.userConfiguration}</FormHelperText>
                  )}
                </FormControl>
              </InputItem>
              <InputItem
                className="small-input"
                label={getTranslationByLabel("badge-id")}
              >
                <TextField
                  fullWidth
                  id="badgeId"
                  name="badgeId"
                  type="text"
                  placeholder={`${getTranslationByLabel("enter-badge-id")}...`}
                  value={values.badgeId ?? ""}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.badgeId && Boolean(errors.badgeId)}
                  helperText={(touched.badgeId && errors.badgeId) || " "}
                />
              </InputItem>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isEnabled"
                    checked={values.isEnabled}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-user-profile")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isHandicap"
                    checked={values.isHandicap}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("is-user-handicap")}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isLockerFeatureEnabled}
                    name="userConfiguration.lockerEmailNotificationsEnabled"
                    checked={
                      values.userConfiguration?.lockerEmailNotificationsEnabled
                    }
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel(
                  "enable-locker-email-notifications"
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isLockerFeatureEnabled}
                    name="userConfiguration.lockerAppNotificationsEnabled"
                    checked={
                      values.userConfiguration?.lockerAppNotificationsEnabled
                    }
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-locker-app-notifications")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isLockerFeatureEnabled}
                    name="userConfiguration.smsLockerNotificationsEnabled"
                    checked={
                      values.userConfiguration?.smsLockerNotificationsEnabled
                    }
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-locker-sms-notifications")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isNotificationFeatureEnabled}
                    name="userConfiguration.emailNotificationsEnabled"
                    checked={
                      values.userConfiguration?.emailNotificationsEnabled
                    }
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-email-notifications")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isNotificationFeatureEnabled}
                    name="userConfiguration.appNotificationsEnabled"
                    checked={values.userConfiguration?.appNotificationsEnabled}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-app-notifications")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isNotificationFeatureEnabled}
                    name="userConfiguration.smsNotificationsEnabled"
                    checked={values.userConfiguration?.smsNotificationsEnabled}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-sms-notifications")}
              />
            </Box>
            <Typography
              textAlign="left"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {getTranslationByLabel("locations-menu")}
            </Typography>
            <FormControl>
              {touched.locations && Boolean(errors.locations) && (
                <FormHelperText error margin="dense" style={{ margin: 0 }}>
                  {(touched.locations && errors.locations) || " "}
                </FormHelperText>
              )}
              <Box display="flex" width="100%">
                <InputItem
                  className="full-width"
                  label={getTranslationByLabel("available-locations")}
                >
                  <LocationsContainer
                    onAdd={onAddLocation}
                    onChangePage={setAvailableLocationsPage}
                    locations={availableLocations}
                    disabledLocations={values.locations.map((el) => el.id)}
                    page={availableLocationsPage}
                    totalItems={locationsCount}
                  />
                </InputItem>
                <InputItem
                  className="full-width"
                  label={getTranslationByLabel("profile-locations")}
                >
                  <LocationsContainer
                    onRemove={onRemoveLocation}
                    onChangePage={setProfileLocationsPage}
                    locations={profileLocationsToDisplay}
                    page={profileLocationsPage}
                    disabledLocations={values.locations.map((el) => el.id)}
                    totalItems={values.locations.length}
                  />
                </InputItem>
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions className="actions">
          {!!id && selfUser && selfUser.role === "MARVELOCK_ADMIN" && (
            <>
              <Button
                className="button"
                variant="text"
                disabled={isSubmitting}
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              >
                {getTranslationByLabel("delete")}
              </Button>
            </>
          )}
          <Button
            className="button"
            variant="contained"
            autoFocus
            disabled={isSubmitting || !dirty}
            onClick={submitForm}
          >
            {getTranslationByLabel("submit")}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        // disabled={isSubmitting}
        onConfirm={handleDelete}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};

interface LocationsContainerProps {
  onAdd?: (id: string) => void;
  onRemove?: (id: string) => void;
  onChangePage: (page: number) => void;
  page: number;
  locations: Location.Type[];
  totalItems: number;
  disabledLocations: string[];
}

const LocationsContainer = ({
  onAdd,
  onRemove,
  onChangePage,
  locations,
  totalItems,
  page,
  disabledLocations,
}: LocationsContainerProps) => {
  const { t: getTranslationByLabel, i18n } = useTranslation();
  const isAdd = !onRemove;

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      alignItems="center"
    >
      <Box className="location-container">
        {locations.map((location) => (
          <Box
            key={location.id}
            display="flex"
            justifyContent="space-between"
            my={0.2}
          >
            <Typography variant="body1" component="span">
              {location.name}, {location.building}
            </Typography>
            <Button
              onClick={() => {
                if (isAdd && onAdd) {
                  onAdd(location.id);
                } else if (onRemove) {
                  onRemove(location.id);
                  if (
                    locations.length - 1 <= LOCATION_ITEMS_PER_PAGE &&
                    page > 1
                  ) {
                    onChangePage(1);
                  }
                }
              }}
              disabled={disabledLocations.includes(location.id) && isAdd}
              startIcon={<FontAwesomeIcon icon={isAdd ? faPlus : faClose} />}
              variant={isAdd ? "contained" : "outlined"}
              className="location-button"
            >
              {getTranslationByLabel(isAdd ? "add" : "remove")}
            </Button>
          </Box>
        ))}
      </Box>
      <Pagination
        sx={{ marginTop: 1 }}
        onChange={(_, page) => {
          onChangePage(page);
        }}
        page={page ?? 1}
        count={Math.ceil(totalItems / LOCATION_ITEMS_PER_PAGE)}
        variant="outlined"
        color="primary"
        shape="rounded"
      />
    </Box>
  );
};

interface InputItemProps {
  label: string;
  className?: string;
}

const InputItem: React.FC<InputItemProps> = ({
  children,
  label,
  className,
}) => {
  return (
    <Box display="flex" flexDirection="column" mr={2} className={className}>
      <Typography
        component="p"
        textAlign="left"
        textTransform="uppercase"
        variant="caption"
        color="primary"
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};

export default UserDialog;
