import { Typography } from "@mui/material";
import MuiSelect, { SelectProps } from "@mui/material/Select";

const Select = (props: SelectProps) => {
  return (
    <MuiSelect
      {...props}
      style={{
        ...props.style,
        height: "56px",
      }}
      renderValue={(value) => {
        let valueToDisplay = props.placeholder;
        if (!props.multiple && value) {
          valueToDisplay = value as any;
        }
        return (
          <Typography
            variant="subtitle1"
            color={value ? "default" : "textSecondary"}
            style={{ fontSize: "16px" }}
            noWrap
          >
            {valueToDisplay}
          </Typography>
        );
      }}
    >
      {props.children}
    </MuiSelect>
  );
};

export default Select;
