import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import notificationReducer from "./notificationSlice";
import serviceOrdersReducer from "./serviceOrdersSlice";
import systemNotificationsReducer from "./systemNotificationSlice";
import locationsReducer from "./locationsSlice";
import lockersReducer from "./lockersSlice";
import reportsReducer from "./reportsSlice";

const store = configureStore({
  reducer: {
    notification: notificationReducer,
    user: userReducer,
    serviceOrders: serviceOrdersReducer,
    systemNotifications: systemNotificationsReducer,
    locations: locationsReducer,
    lockers: lockersReducer,
    reports: reportsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
