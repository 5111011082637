import { useTheme } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

interface OwnProps {
  open: boolean;
}

const WithLoadingBackdrop: React.FC<OwnProps> = function ({ children, open }) {
  const theme = useTheme();
  return (
    <>
      {children}
      <Backdrop
        style={{
          zIndex: theme.zIndex.drawer + 1,
          color: "#fff",
        }}
        open={open}
      >
        <CircularProgress size={80} />
      </Backdrop>
    </>
  );
};

export default WithLoadingBackdrop;
