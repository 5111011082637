import { Button, ButtonProps } from "@mui/material";
import { useTranslation } from "react-i18next";

interface OwnProps extends Omit<ButtonProps, "onClick"> {
  valueToCopy: string;
  label?: string;
}

const CopyToClipboardButton = ({ label, valueToCopy, ...props }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  return (
    <Button
      style={{ height: 55 }}
      {...props}
      onClick={() => {
        navigator.clipboard.writeText(valueToCopy);
      }}
    >
      {label ?? getTranslationByLabel("copy-id")}
    </Button>
  );
};

export default CopyToClipboardButton;
