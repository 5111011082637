import { Box, Button, Pagination, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { sendHealthCheckForAllLockers } from "../api/lockers";
import PageWrapper from "../common/PageWrapper";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import HealthCheckDialog, {
  HealthCheckType,
} from "../components/HealthCheckDialog";
import LockerDialog from "../components/LockerDialog";
import LockerItem from "../components/LockerItem";
import FilterBar from "../components/FilterBar";
import { useAppDispatch, useAppSelector } from "../hooks";
import { usePagination } from "../hooks/usePagination";
import {
  getLockers,
  selectIsLoading,
  selectItems,
  selectItemsCount,
  selectPages,
} from "../store/lockersSlice";
import { useLocation } from "react-router-dom";

const Lockers = () => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel, i18n } = useTranslation();
  const location = useLocation();
  const isLoading = useAppSelector(selectIsLoading);
  const items = useAppSelector(selectItems);
  const pages = useAppSelector(selectPages);
  const count = useAppSelector(selectItemsCount);
  const [page, setPage] = usePagination();
  const [dialogOpened, setDialogOpened] = useState(false);
  const [editId, setEditId] = useState("");
  const [healthCheckType, setHealthCheckType] = useState<HealthCheckType>();

  useEffect(() => {
    // Decrease and increase with 1, because the API has zero based pagination filter
    if (page) {
      dispatch(getLockers(page - 1));
    }
  }, [page]);

  const filterBarLabel = useMemo(() => {
    if (isLoading || !page) {
      return getTranslationByLabel("loading");
    }
    let labelNumber = page * 10;
    if (count < 10 || labelNumber > count) {
      labelNumber = count;
    }
    return `${labelNumber}/${count} ${getTranslationByLabel(
      "lockers-menu"
    ).toLowerCase()}`;
  }, [count, page, isLoading, i18n.language]);

  return (
    <WithLoadingBackdrop open={isLoading}>
      <FilterBar
        withButton
        label={filterBarLabel}
        secondaryButton={
          <Button
            variant="outlined"
            sx={{
              marginLeft: "24px",
              height: "58px",
              minWidth: "180px",
            }}
            onClick={() => {
              sendHealthCheckForAllLockers().then(() => {
                setHealthCheckType(HealthCheckType.ALL);
              });
            }}
          >
            {getTranslationByLabel("send-health-check")}
          </Button>
        }
        onClick={() => {
          setDialogOpened(true);
        }}
      />
      <PageWrapper>
        {items.map((item) => (
          <LockerItem
            key={item.id}
            item={item}
            onEdit={() => {
              setEditId(item.id);
              setDialogOpened(true);
            }}
            onSendHealthCheck={() => {
              setHealthCheckType(HealthCheckType.SINGLE);
            }}
          />
        ))}
        {!items.length && !isLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
          >
            <Typography variant="h3" color="textSecondary">
              {getTranslationByLabel("no-items")}
            </Typography>
          </Box>
        )}
        {!!items.length && !isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" p={1}>
            <Pagination
              onChange={(_, page) => {
                // Decrease and increase with 1, because the API has zero based pagination filter
                setPage(page, location.search);
              }}
              page={page ?? 1}
              count={pages}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Box>
        )}
      </PageWrapper>
      <HealthCheckDialog
        open={!!healthCheckType}
        type={healthCheckType}
        onClose={() => {
          setHealthCheckType(undefined);
        }}
      />
      <LockerDialog
        open={dialogOpened}
        id={editId}
        onClose={(shouldRefetch?: boolean) => {
          setEditId("");
          setDialogOpened(false);
          if (shouldRefetch) {
            dispatch(getLockers(page ? page - 1 : 0));
          }
        }}
      />
    </WithLoadingBackdrop>
  );
};

export default Lockers;
