import { Box, Pagination, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PageWrapper from "../common/PageWrapper";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import ServiceOrderItem from "../components/ServiceOrderItem";
import FilterBar, { FilterItem } from "../components/FilterBar";
import { useAppDispatch, useAppSelector } from "../hooks";
import { usePagination } from "../hooks/usePagination";
import {
  getServiceOrders,
  selectIsLoading,
  selectItems,
  selectItemsCount,
  selectPages,
} from "../store/serviceOrdersSlice";
import { ServiceOrder } from "../entities";
import { useLocation } from "react-router-dom";

const FILTER_KEY = "state";

const ServiceOrders = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t: getTranslationByLabel } = useTranslation();
  const isLoading = useAppSelector(selectIsLoading);
  const items = useAppSelector(selectItems);
  const pages = useAppSelector(selectPages);
  const count = useAppSelector(selectItemsCount);
  const [page, setPage] = usePagination();
  const [selectedFilters, setSelectedFilters] =
    useState<ServiceOrder.State[]>();

  const filterBarLabel = useMemo(() => {
    if (isLoading || !page) {
      return getTranslationByLabel("loading");
    }
    let labelNumber = page * 10;
    if (count < 10 || labelNumber > count) {
      labelNumber = count;
    }
    return `${labelNumber}/${count}`;
  }, [count, page, isLoading]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    setSelectedFilters(
      (urlSearchParams.getAll(FILTER_KEY) as ServiceOrder.State[]) ?? undefined
    );
  }, [location.search]);

  useEffect(() => {
    // Decrease and increase with 1, because the API has zero based pagination filter
    if (page) {
      dispatch(getServiceOrders({ page: page - 1, filters: selectedFilters }));
    }
  }, [page, selectedFilters]);

  const filterItems: FilterItem[] = useMemo(
    () => [
      {
        key: "all",
        label: getTranslationByLabel("all"),
      },
      {
        key: "active",
        label: getTranslationByLabel("available"),
      },
      {
        key: "inactive",
        label: getTranslationByLabel("delivered"),
      },
      // {
      //   key: "attention",
      //   label: getTranslationByLabel("attention"),
      // },
    ],
    []
  );

  return (
    <WithLoadingBackdrop open={isLoading}>
      <FilterBar
        filterKey={FILTER_KEY}
        label={filterBarLabel}
        items={filterItems}
        singleFilter
      />
      <PageWrapper>
        {items.map((item) => (
          <ServiceOrderItem key={item.id} item={item} />
        ))}
        {!items.length && !isLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
          >
            <Typography variant="h3" color="textSecondary">
              {getTranslationByLabel("no-items")}
            </Typography>
          </Box>
        )}
        {!!items.length && !isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" p={1}>
            <Pagination
              onChange={(_, page) => {
                // Decrease and increase with 1, because the API has zero based pagination filter
                setPage(page, location.search);
              }}
              page={page ?? 1}
              count={pages}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Box>
        )}
      </PageWrapper>
    </WithLoadingBackdrop>
  );
};

export default ServiceOrders;
