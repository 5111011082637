import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "../../common/CopyToClipboardButton";
import ItemCard from "../../common/ItemCard";
import { ServiceOrder } from "../../entities";
import { useAppSelector } from "../../hooks";
import { selectSelfUser } from "../../store/userSlice";
import "./style.scss";

interface OwnProps {
  item: ServiceOrder.Type;
}
const ServiceOrderItem = ({ item }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const selfUser = useAppSelector(selectSelfUser);
  const bin = useMemo(() => item.bins[0], [item]);
  const status = useMemo(() => {
    if (item.state === "active") {
      return "available";
    }
    if (item.state === "attention") {
      return "attention";
    }
    return "delivered";
  }, [item]);

  const statusClassName = useMemo(() => {
    if (status === "available") {
      return "active";
    }
    if (status === "attention") {
      return "attention";
    }
    return "inactive";
  }, [status]);

  return (
    <div className="service-order-item-conteiner">
      <ItemCard>
        <Box display="flex" alignItems="center">
          <div className={classNames("item", "large")}>
            <div className={classNames(statusClassName, "status-item")}>
              <Typography
                textAlign="left"
                component="p"
                variant="caption"
                color={status === "available" ? "white" : "primary"}
              >
                {getTranslationByLabel(status)}
              </Typography>
            </div>
            <Typography textAlign="left" variant="h6" color="primary">
              {bin.locker.location.address}
            </Typography>
            <Typography
              textAlign="left"
              variant="caption"
              color="primary"
              component="p"
            >
              {bin.locker.location.building}
            </Typography>
          </div>
          <div className={classNames("item", "small")}>
            <TextItem
              label={getTranslationByLabel("locker-id")}
              description={bin.locker.name}
            />
          </div>
          <div className={classNames("item", "small")}>
            <TextItem
              label={getTranslationByLabel("bin-number")}
              description={String(bin.number)}
            />
          </div>
          {selfUser && selfUser.role === "RESIDENT" && (
            <div className={classNames("item", "large")}>
              <TextItem
                label={getTranslationByLabel("access-code-resident")}
                description={item.accessCode}
              />
            </div>
          )}
          {selfUser &&
            (selfUser.role === "MARVELOCK_ADMIN" ||
              selfUser.role === "SITE_MANAGER") && (
              <div className={classNames("item", "large")}>
                <TextItem
                  label={getTranslationByLabel("access-code-manager")}
                  description={item.accessCodeManager}
                />
              </div>
            )}
          <div className={classNames("item", "small")}>
            <CopyToClipboardButton
              fullWidth
              variant="outlined"
              valueToCopy={item.id}
            />
          </div>
        </Box>
      </ItemCard>
    </div>
  );
};

interface TextItemProps {
  label: string;
  description: string;
}

const TextItem = ({ label, description }: TextItemProps) => {
  return (
    <div className={classNames("item", "small")}>
      <Typography
        component="p"
        textAlign="left"
        textTransform="uppercase"
        variant="caption"
        color="primary"
      >
        {label}
      </Typography>
      <Typography
        className="description-label"
        textAlign="left"
        variant="h6"
        color="primary"
      >
        {description}
      </Typography>
    </div>
  );
};

export default ServiceOrderItem;
