// import { AlertProps } from "@material-ui/lab/Alert";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum EnumNotificationType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}
export interface NofiticationState {
  text?: string;
  type?: EnumNotificationType;
  duration?: number;
}

const initialState: NofiticationState = {};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<NofiticationState>) => {
      state.text = action.payload.text;
      state.type = action.payload.type;
      if (action.payload.duration) {
        state.duration = action.payload.duration;
      }
    },
    hideNotification: (state) => {
      state.text = undefined;
      state.type = undefined;
    },
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
