import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export enum AvailableLanguages {
  EN = "en",
  FR = "fr",
}

export const DEFAULT_LANGUAGE = AvailableLanguages.EN;

i18n.use(initReactI18next).init({
  fallbackLng: AvailableLanguages.EN,
  lng: AvailableLanguages.EN,
  resources: {
    en: {
      translations: require("./locales/en_translations.json"),
    },
    fr: {
      translations: require("./locales/fr_translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = [AvailableLanguages.EN, AvailableLanguages.FR];

export default i18n;
