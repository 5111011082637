import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "../../common/CopyToClipboardButton";
import ItemCard from "../../common/ItemCard";
import { Location } from "../../entities";
import "./style.scss";

interface OwnProps {
  item: Location.Type;
  onEdit: () => void;
}
const LocationItem = ({ item, onEdit }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();

  return (
    <div className="location-item-conteiner">
      <ItemCard>
        <Box display="flex" alignItems="center">
          <div className={classNames("item", "large")}>
            <Typography
              overflow="hidden"
              textOverflow="ellipsis"
              textAlign="left"
              variant="h6"
              color="primary"
            >
              {item.name}
            </Typography>
            <Typography
              textAlign="left"
              variant="caption"
              color="primary"
              component="p"
            >
              {item.building}
            </Typography>
          </div>
          <div className={classNames("item", "large")}>
            <TextItem
              label={getTranslationByLabel("address")}
              description={item.address}
            />
          </div>
          <div className={classNames("item", "small")}>
            <TextItem
              label={getTranslationByLabel("company")}
              description={item.companyName ?? "N/A"}
            />
          </div>
          <CopyToClipboardButton
            className={classNames("small", "button")}
            fullWidth
            variant="outlined"
            valueToCopy={item.id}
          />
          <Button
            className={classNames("small", "button")}
            variant="contained"
            fullWidth
            onClick={onEdit}
          >
            {getTranslationByLabel("edit")}
          </Button>
        </Box>
      </ItemCard>
    </div>
  );
};

interface TextItemProps {
  label: string;
  description: string;
}

const TextItem = ({ label, description }: TextItemProps) => {
  return (
    <div className={classNames("item", "small")}>
      <Typography
        component="p"
        textAlign="left"
        textTransform="uppercase"
        variant="caption"
        color="primary"
      >
        {label}
      </Typography>
      <Typography
        className="description-label"
        textAlign="left"
        variant="h6"
        color="primary"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {description}
      </Typography>
    </div>
  );
};

export default LocationItem;
