import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { setInterceptApiRequest, setInterceptApiResponse } from "./api";
import "./App.css";
import WithLoadingBackdrop from "./common/WithLoadingBackdrop";
import Snackbar from "./components/Snackbar";
import { ACCESS_TOKEN_KEY, SELECTED_LANGUAGE_KEY } from "./constants";
import { DEFAULT_LANGUAGE } from "./i18n/config";
import OCTA_AUTH_CONFIG from "./octa-config";
import ForgotPassword from "./pages/ForgotPassword";
import HomeProtected from "./pages/HomeProtected";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Welcome from "./pages/Welcome";
import {
  APPLICATION_PATH,
  BASE_PATH,
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
} from "./routes";
import store from "./store";
moment.tz.setDefault("Europe/Paris");

const oktaAuth = new OktaAuth(OCTA_AUTH_CONFIG);

const App = () => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const customAuthHandler = () => {
    history.push(LOGIN_PATH);
  };

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    history.replace(
      toRelativeUrl(originalUri || APPLICATION_PATH, window.location.origin)
    );
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem(SELECTED_LANGUAGE_KEY);
    if (!savedLanguage) {
      localStorage.setItem(SELECTED_LANGUAGE_KEY, DEFAULT_LANGUAGE);
    }
    if (savedLanguage && savedLanguage !== i18n.language) {
      i18n.changeLanguage(savedLanguage);
    }
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      Promise.all([
        setInterceptApiRequest(token),
        setInterceptApiResponse(),
      ]).then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    <WithLoadingBackdrop open />;
  }

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="root">
          <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
          >
            <Switch>
              <Route exact path={BASE_PATH} component={Welcome} />
              <Route exact path={LOGIN_PATH} component={Login} />
              <Route
                exact
                path={FORGOT_PASSWORD_PATH}
                component={ForgotPassword}
              />
              <Route
                path={`${LOGIN_PATH}/callback`}
                component={LoginCallback}
              />
              <SecureRoute path={APPLICATION_PATH} component={HomeProtected} />
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Security>
          <Snackbar />
        </div>
      </LocalizationProvider>
    </Provider>
  );
};

export default App;
