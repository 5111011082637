import axios from "axios";
import { PartialApiItemsResponse } from ".";
import { Locker } from "../entities";

const mockData: any = {
  content: [
    {
      id: "bc1f5743-f975-430a-8b6b-00718ee54eb4",
      globalTemp: 2,
      globalHumidity: 64,
      vibration: true,
      name: "Marvelock locker 1",
      contract: "Marvelock",
      mode: "WINTER",
      installationDate: "2020-07-16",
      location: {
        id: "0d953309-dd5f-418f-9034-63337b426e2f",
        latitude: 12.11111,
        longitude: 23.22222,
        city: "Grenoble",
        country: "France",
        address: "3 rue Beyle Stendhal",
        building: "Building A",
        floor: 0,
        phoneNumber: "0474992156",
        name: "Contoso Grenoble",
        image:
          "https://cf.bstatic.com/images/hotel/max1024x768/178/178522460.jpg",
        organization: "ECONCIERGE",
        locationFeature: {
          id: "aa90bfd2-415d-452d-b0ce-9152e0bab962",
          lockerFeatureEnabled: true,
          notificationFeatureEnabled: true,
          smsFeatureEnabled: true,
        },
      },
      bins: [
        {
          id: "fd5b430c-f49a-4da3-9ccb-4e06989439e5",
          number: 11,
          isEmpty: true,
          binDoor: {
            id: "84c9d0d3-3ce8-44c7-b020-0fbca0dec40a",
            status: "OPEN",
          },
          binUvcLamp: {
            id: "21838279-f8c1-4831-b8cf-b41337f8c360",
            status: "DISABLED",
          },
          binHeater: {
            id: "18d8f33e-ff6f-4384-832d-431ef34c2b26",
            status: "DISABLED",
          },
        },
        {
          id: "7e3c567d-d956-4e65-b3bb-31d98a1fe41c",
          number: 13,
          isEmpty: true,
          binDoor: {
            id: "4f2773da-bbf0-47e4-bd86-c2531f70cbd7",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "e6a1ddf6-0170-446a-98a5-9e178780f029",
            status: "DISABLED",
          },
          binHeater: {
            id: "355da569-4d96-4a00-b358-d9558d480846",
            status: "DISABLED",
          },
        },
        {
          id: "0d997ea5-659d-474e-b156-3b715b640429",
          number: 1,
          isEmpty: true,
          binDoor: {
            id: "2fdbd780-598b-4ce8-af77-b69a7e083963",
            status: "OPEN",
          },
          binUvcLamp: {
            id: "6215ce97-4393-4ed6-82a2-4732b21001b0",
            status: "DISABLED",
          },
          binHeater: {
            id: "40a96612-4647-45dd-a313-b48a7cb64dde",
            status: "DISABLED",
          },
        },
        {
          id: "038e3955-4122-4aae-8861-ece14303dbb0",
          number: 3,
          isEmpty: true,
          binDoor: {
            id: "31119954-2d25-4465-9c79-de69f7546db2",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "1c56b65c-f20d-43d5-b35a-c7b99b4a2ff0",
            status: "DISABLED",
          },
          binHeater: {
            id: "75da636e-6752-4bcd-a05b-f7a8915c93bf",
            status: "DISABLED",
          },
        },
        {
          id: "a262914c-74b8-4f5f-9d67-4235b1a6c778",
          number: 14,
          isEmpty: true,
          binDoor: {
            id: "9b53f2b7-cf58-48a2-81c2-c1f926aaa3ec",
            status: "OPEN",
          },
          binUvcLamp: {
            id: "01d5f29b-ac36-4bed-a42d-08e4ce49c136",
            status: "DISABLED",
          },
          binHeater: {
            id: "8ae96400-52a1-4d16-8a5c-667158f686a9",
            status: "DISABLED",
          },
        },
        {
          id: "8057b7bd-14e6-434b-8bd6-6d9129089f78",
          number: 5,
          isEmpty: true,
          binDoor: {
            id: "3e836f49-b8f5-47a7-904b-32855eed6fe5",
            status: "OPEN",
          },
          binUvcLamp: {
            id: "555de1ca-1e36-4f39-b5d8-77bd83401828",
            status: "DISABLED",
          },
          binHeater: {
            id: "96d10e6f-aeab-4e4f-bb69-44e18064f783",
            status: "DISABLED",
          },
        },
        {
          id: "fc98372e-4654-40a0-a3a2-e78bcbdf24fb",
          number: 6,
          isEmpty: true,
          binDoor: {
            id: "b368ea3f-324f-454c-bdcb-437e47688da3",
            status: "OPEN",
          },
          binUvcLamp: {
            id: "5dd169d5-ea18-45f8-b63d-fff7b438ec61",
            status: "DISABLED",
          },
          binHeater: {
            id: "5804685a-cded-48dd-a8c3-4b5debcc9052",
            status: "DISABLED",
          },
        },
        {
          id: "5771f2f8-8aa1-411d-8f80-358964e83574",
          number: 15,
          isEmpty: true,
          binDoor: {
            id: "d645f97b-c72a-4394-a233-7baf17deb470",
            status: "OPEN",
          },
          binUvcLamp: {
            id: "4a798f9f-860e-4bce-a95a-aabc0d272fb4",
            status: "DISABLED",
          },
          binHeater: {
            id: "39895b62-b556-4759-bd63-d3d6856517da",
            status: "DISABLED",
          },
        },
        {
          id: "772a1632-25b2-42a2-ba0a-f0cb6de7f880",
          number: 16,
          isEmpty: true,
          binDoor: {
            id: "67efa924-dc95-43f7-b4fb-9b5b92330a12",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "bdba5353-3d95-49d1-b4f9-521fbe2b488c",
            status: "DISABLED",
          },
          binHeater: {
            id: "2dc215ee-6a8d-4087-a150-bbaf87cda823",
            status: "DISABLED",
          },
        },
        {
          id: "1fde0473-8c33-404e-a760-bb83012f0ecf",
          number: 2,
          isEmpty: true,
          binDoor: {
            id: "35399a0b-0be3-4131-a5bb-6ee7ef4bdb27",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "9b74503c-689f-43e1-8938-b0c2e7597606",
            status: "DISABLED",
          },
          binHeater: {
            id: "dde9f16e-c809-4428-af50-9698c890076e",
            status: "DISABLED",
          },
        },
        {
          id: "a5ec8f12-a1cb-4eb5-afd1-75bfe1674a89",
          number: 4,
          isEmpty: true,
          binDoor: {
            id: "c3d2d737-206c-4d96-b6fc-9fcc16500f44",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "a3a70b8e-e44c-4c21-b6c5-52aa88c8e626",
            status: "DISABLED",
          },
          binHeater: {
            id: "417a6c0c-5044-4d19-b6ca-8213654f284b",
            status: "DISABLED",
          },
        },
        {
          id: "29a536e8-f77e-426f-b853-1a4184cef2c5",
          number: 8,
          isEmpty: true,
          binDoor: {
            id: "edc46703-2c2c-47eb-b9cb-0d2f9fc4b72d",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "bb4c8022-4b64-4c28-a034-c93c2bc657a0",
            status: "DISABLED",
          },
          binHeater: {
            id: "70d0cfcb-86dc-481b-b51d-55333157764d",
            status: "DISABLED",
          },
        },
        {
          id: "bada7b1b-060f-4060-ae85-6020fb49d968",
          number: 9,
          isEmpty: true,
          binDoor: {
            id: "12ce00c0-96bb-48a8-8331-f7e8cc138e74",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "a1cbb3e9-a866-4b5b-992d-2f978d6c06fb",
            status: "DISABLED",
          },
          binHeater: {
            id: "177a1b05-b349-4852-8670-bebd6116e5fb",
            status: "DISABLED",
          },
        },
        {
          id: "1305f758-38b4-4109-b074-76eec45cea30",
          number: 10,
          isEmpty: true,
          binDoor: {
            id: "82746ea1-71bc-45a8-8bf4-f500ea2362fa",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "d2267baa-f5d5-4f45-a3c5-1112484523ae",
            status: "DISABLED",
          },
          binHeater: {
            id: "b1d841bf-e906-4559-9c19-38a85c1674e1",
            status: "DISABLED",
          },
        },
        {
          id: "62842b45-c264-45bc-ac7d-a887a13a2193",
          number: 18,
          isEmpty: true,
          binDoor: {
            id: "521dae6c-88bb-4ed7-a14a-d6817799c6b8",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "98c07bee-ba9e-4895-a635-89b94f79b269",
            status: "DISABLED",
          },
          binHeater: {
            id: "5114cfef-d04f-455c-ad41-6f30697db79b",
            status: "DISABLED",
          },
        },
        {
          id: "5f736f25-251b-4b1f-bc5d-93193aa02009",
          number: 19,
          isEmpty: true,
          binDoor: {
            id: "2ce6da3b-54b5-4ae8-b6f9-0597a43b5448",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "e1a452d1-ab97-4a4d-8b06-4a0479ccad57",
            status: "DISABLED",
          },
          binHeater: {
            id: "c582b1d4-6d0a-4cfc-b4a5-ed5945fca37e",
            status: "DISABLED",
          },
        },
        {
          id: "6c5aaf04-6bf7-4fa2-b1f1-df8f27a2c110",
          number: 20,
          isEmpty: true,
          binDoor: {
            id: "873bd189-b72a-4cab-a2ad-913beb6c8429",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "e25b13a7-d64a-4f4e-aabb-84ea72692f50",
            status: "DISABLED",
          },
          binHeater: {
            id: "f39c9a82-2554-4014-8e03-2a715067dcb0",
            status: "DISABLED",
          },
        },
        {
          id: "b8000228-35b9-47ce-96eb-98aab4951447",
          number: 21,
          isEmpty: true,
          binDoor: {
            id: "4e687988-d693-4d20-ba8b-dfb790414958",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "41c62d3b-bdf0-4f65-9bdf-13b0187cee96",
            status: "DISABLED",
          },
          binHeater: {
            id: "13c7d009-1f65-40d3-a241-20fd3525e880",
            status: "DISABLED",
          },
        },
        {
          id: "b12d096d-b7fb-43eb-96b6-49a8889f7165",
          number: 23,
          isEmpty: true,
          binDoor: {
            id: "e9084ac8-9316-4595-b119-25fe774cb34b",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "77717f3e-3de7-42ba-8c27-4080a039b59c",
            status: "DISABLED",
          },
          binHeater: {
            id: "6c287c3f-06ce-4d75-9e18-2df0fc1c70ca",
            status: "DISABLED",
          },
        },
        {
          id: "d7ce0a05-1dd1-469e-a5a2-9d65c6990a9f",
          number: 24,
          isEmpty: true,
          binDoor: {
            id: "585e782a-c389-4e11-8ee4-bc817ef98476",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "cef6b972-c047-4268-ba5e-a1a2684dc7ab",
            status: "DISABLED",
          },
          binHeater: {
            id: "1df75e29-280f-472e-ad65-02b44a249ad4",
            status: "DISABLED",
          },
        },
        {
          id: "92988cae-2a47-4187-bb99-78c89ecbef7f",
          number: 25,
          isEmpty: true,
          binDoor: {
            id: "0aebba99-33f3-4191-81f9-89f28210e6da",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "f3f5ea28-c388-467f-b142-b8c44ca58e90",
            status: "DISABLED",
          },
          binHeater: {
            id: "6cfb6566-e739-4b7e-b163-8e7cb9828f52",
            status: "DISABLED",
          },
        },
        {
          id: "0cac2054-409b-4cfa-b677-3f316bae2d21",
          number: 26,
          isEmpty: true,
          binDoor: {
            id: "1508f60c-9065-4095-a003-4f1e84fa8c23",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "c7131ccf-1ad1-4d1d-bc10-17be23602d80",
            status: "DISABLED",
          },
          binHeater: {
            id: "444c679f-5bf8-4746-bc13-bacb19909fbf",
            status: "DISABLED",
          },
        },
        {
          id: "c40c930a-d629-4431-b311-56cbf4f62038",
          number: 17,
          isEmpty: true,
          binDoor: {
            id: "3e580bc6-37e1-4dd6-afe7-6ca72add2fa0",
            status: "CLOSE",
            size: 3,
          },
          binUvcLamp: {
            id: "377845e2-060c-4b8f-abb3-febd55c43073",
            status: "DISABLED",
          },
          binHeater: {
            id: "37cbaad9-c8c9-41e6-9677-8f41449d83b6",
            status: "DISABLED",
          },
        },
        {
          id: "d2e9632f-4a77-4853-b717-f68d9142dd4a",
          number: 7,
          isEmpty: true,
          binDoor: {
            id: "ec9b1c37-1197-4acb-b13a-c18e8c38856a",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "372d225f-bd8e-4130-b2ed-bb30eb470930",
            status: "DISABLED",
          },
          binHeater: {
            id: "2b1107a1-98eb-4b0c-9a63-f58019947e2b",
            status: "DISABLED",
          },
        },
        {
          id: "78c59468-33c9-4631-a28a-bd5b0862c94f",
          number: 12,
          isEmpty: true,
          binDoor: {
            id: "a3449968-ddd0-4c7a-b067-e490041fc365",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "83d176e7-4db6-425e-a4e3-626cddf24932",
            status: "DISABLED",
          },
          binHeater: {
            id: "ccc29872-3d42-47ce-90e4-666e99c2455c",
            status: "DISABLED",
          },
        },
        {
          id: "01634d38-a1c4-4bd3-bd62-c32483dc65b4",
          number: 22,
          isEmpty: true,
          binDoor: {
            id: "e1f968f8-65d9-413b-8777-252cfe1871f2",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "e3e0b85e-068e-4c71-8ed9-48dce776a463",
            status: "DISABLED",
          },
          binHeater: {
            id: "e5f537ea-f25c-4bee-9faa-4c27947694b3",
            status: "DISABLED",
          },
        },
      ],
      organization: "END_USER",
      on: false,
    },
    {
      id: "fd0a339a-c49c-4e33-a325-972627f0b014",
      globalTemp: 2,
      globalHumidity: 64,
      vibration: true,
      name: "Marvelock locker 2",
      contract: "Marvelock",
      mode: "WINTER",
      installationDate: "2020-07-16",
      location: {
        id: "675e71f1-f9f1-417f-ac38-445b3cc7f48b",
        latitude: 11.11111,
        longitude: 22.22222,
        city: "Sofia",
        country: "Bulgaria",
        address: "bg address",
        building: "buildning name",
        floor: 11,
        phoneNumber: "55-555-5555555",
        name: "House Proto 2",
        image:
          "https://cf.bstatic.com/images/hotel/max1024x768/178/178522460.jpg",
        organization: "ECONCIERGE",
        locationFeature: {
          id: "1bb333cb-102f-4608-a492-368884ba145b",
          lockerFeatureEnabled: true,
          notificationFeatureEnabled: true,
          smsFeatureEnabled: true,
        },
      },
      bins: [
        {
          id: "441dd972-b63b-4801-86df-9af070eb5d20",
          number: 1,
          isEmpty: true,
          binDoor: {
            id: "0b984840-edfd-4b05-ac37-149a8dde92b3",
            status: "OPEN",
          },
          binUvcLamp: {
            id: "456def58-7a71-4ee5-b4bf-c56d812b1c0d",
            status: "DISABLED",
          },
          binHeater: {
            id: "6e364d16-e609-4a3b-8165-d3280b61c9a5",
            status: "DISABLED",
          },
        },
        {
          id: "d12b62b3-8a75-4057-ba14-2ef5e39e08ab",
          number: 2,
          isEmpty: true,
          binDoor: {
            id: "2fb3bb56-3820-4d39-b883-2cc74092f4b7",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "7fb40423-07f1-4992-94f2-60dd0ba75ab7",
            status: "DISABLED",
          },
          binHeater: {
            id: "22f97c94-ba98-4574-918f-2dcd116bcb5d",
            status: "DISABLED",
          },
        },
        {
          id: "45099be3-c7d8-4326-a439-c581c6008598",
          number: 3,
          isEmpty: true,
          binDoor: {
            id: "b8a07d39-8b29-4648-910c-8d44f7c98217",
            status: "CLOSE",
          },
          binUvcLamp: {
            id: "01ca842f-a65a-4dcf-8516-9962c109e4b6",
            status: "DISABLED",
          },
          binHeater: {
            id: "442a11b2-4964-4f4a-9b85-e295e2820655",
            status: "DISABLED",
          },
        },
      ],
      organization: "END_USER",
      reservationStartTime: 8,
      reservationEndTime: 8,
      on: false,
    },
    {
      id: "3331defc-1592-4fd9-be5f-b27f08a8d4ab",
      globalTemp: 2,
      globalHumidity: 64,
      vibration: false,
      name: "House Proto 1",
      contract: "Marvelock",
      mode: "WINTER",
      installationDate: "2020-07-16",
      location: {
        id: "586ea792-2af3-44e9-be7e-8eb69edbe52b",
        latitude: 11.11111,
        longitude: 22.22222,
        city: "Lyon",
        country: "France",
        address: "Boulevard Thierry Henry",
        building: "Building A",
        floor: 0,
        phoneNumber: "0474992156",
        name: "Contoso Lyon",
        image:
          "https://cf.bstatic.com/images/hotel/max1024x768/178/178522460.jpg",
        organization: "ECONCIERGE",
        locationFeature: {
          id: "085b51e9-7c20-41e9-8c8d-567aba87f80f",
          lockerFeatureEnabled: true,
          notificationFeatureEnabled: true,
          smsFeatureEnabled: true,
        },
      },
      bins: [
        {
          id: "23236c21-0bc5-4c65-ab17-111f99a15df5",
          number: 2,
          isEmpty: true,
          binDoor: {
            id: "680a9690-56bc-4c78-9753-0e2dee1dc4e8",
            status: "OPEN",
          },
          binUvcLamp: {
            id: "ba56fa29-a93a-417a-9f97-07fc1da6e845",
            status: "DISABLED",
          },
          binHeater: {
            id: "008cb105-79ae-4f5a-8730-0b810b163de9",
            status: "DISABLED",
          },
        },
        {
          id: "21f743d9-a761-47e4-93b0-67ed1c4e784f",
          number: 1,
          isEmpty: true,
          binDoor: {
            id: "761de919-61a4-4318-91f0-9c11602e73a9",
            status: "OPEN",
          },
          binUvcLamp: {
            id: "627730cb-4504-48a1-a224-82244298ce10",
            status: "DISABLED",
          },
          binHeater: {
            id: "16aa0f32-6f81-442f-95a2-6d973190738a",
            status: "DISABLED",
          },
        },
        {
          id: "48fdf3c8-be4b-4a19-909a-94ced1c690e7",
          number: 3,
          isEmpty: true,
          binDoor: {
            id: "1de7489d-b74c-4caf-a02f-197785ed9f17",
            status: "OPEN",
          },
          binUvcLamp: {
            id: "01189e24-696a-4673-80c5-7d77a3ed8b3e",
            status: "DISABLED",
          },
          binHeater: {
            id: "0b21f54c-9089-475f-a9a3-2bc1386f1c64",
            status: "DISABLED",
          },
        },
      ],
      organization: "END_USER",
      on: true,
    },
  ],
  pageable: {
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    pageNumber: 0,
    pageSize: 10,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  last: true,
  totalElements: 3,
  totalPages: 1,
  first: true,
  sort: {
    sorted: false,
    unsorted: true,
    empty: true,
  },
  number: 0,
  numberOfElements: 3,
  size: 5,
  empty: false,
};

export interface LockerResponse extends PartialApiItemsResponse {
  content: Locker.Type[];
}

export const fetchLockers = (page: number, items: number) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("page", String(page));
  urlSearchParams.append("items", String(items));
  urlSearchParams.append("sortDirection", "ASC");
  // return new Promise<LockerResponse>((resolve) => {
  //   setTimeout(() => {
  //     resolve(mockData);
  //   }, 1000);
  // });
  return new Promise<LockerResponse>((resolve, reject) => {
    axios
      .get(`/v2/lockers?${urlSearchParams.toString()}`)
      .then((response) => {
        resolve(response.data ?? {});
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const refreshLocker = (id: string): Promise<Locker.Type> => {
  return axios.get(`/v1/lockers/${id}`).then((response) => response.data);
};

export const sendHealthCheck = (id: string) => {
  return axios.post(`/v2/lockers/${id}/health`);
};

export const sendHealthCheckForAllLockers = () => {
  return axios.post("/v2/lockers/monitor");
};

export const submitLocker = (item: Partial<Locker.Type>, isNew?: boolean) => {
  if (isNew) {
    return axios.post("/v2/lockers", { ...item, contract: "test" });
  } else {
    return axios.put(`/v2/lockers/${item.id}`, { ...item, contract: "test" });
  }
};

export const deleteLocker = (id: string) => {
  return axios.delete(`/v1/lockers/${id}`);
};
