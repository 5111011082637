import { OktaAuthOptions } from "@okta/okta-auth-js";
import { CLIENT_ID, ISSUER } from "./constants";
import { LOGIN_PATH } from "./routes";

const config: OktaAuthOptions = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  scopes: ["openid", "profile", "email", "offline_access"],
  redirectUri: `${window.location.origin}${LOGIN_PATH}/callback`,
};

export default config;
