import { Box, Pagination, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PageWrapper from "../common/PageWrapper";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import NotificationItem from "../components/NotificationItem";
import FilterBar, { FilterItem } from "../components/FilterBar";
import SystemNotificationDialog from "../components/SystemNotificationDialog";
import { useAppDispatch, useAppSelector } from "../hooks";
import { usePagination } from "../hooks/usePagination";
import {
  getSystemNotifications,
  selectIsLoading,
  selectItems,
  selectItemsCount,
  selectPages,
} from "../store/systemNotificationSlice";
import { selectSelfUser } from "../store/userSlice";
import { SystemNotification } from "../entities";
import { useLocation } from "react-router-dom";

const FILTER_KEY = "state";

const Notifications = () => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel, i18n } = useTranslation();
  const location = useLocation();
  const selfUser = useAppSelector(selectSelfUser);
  const isLoading = useAppSelector(selectIsLoading);
  const items = useAppSelector(selectItems);
  const pages = useAppSelector(selectPages);
  const count = useAppSelector(selectItemsCount);
  const [page, setPage] = usePagination();
  const [dialogOpened, setDialogOpened] = useState(false);
  const [editId, setEditId] = useState("");
  const [selectedFilter, setSelectedFilter] =
    useState<SystemNotification.State>();

  const filterBarLabel = useMemo(() => {
    if (isLoading || !page) {
      return getTranslationByLabel("loading");
    }
    let labelNumber = page * 10;
    if (count < 10 || labelNumber > count) {
      labelNumber = count;
    }
    return `${labelNumber}/${count} ${getTranslationByLabel(
      "notifications-menu"
    ).toLowerCase()}`;
  }, [count, page, isLoading, i18n.language]);

  const filterItems: FilterItem[] = useMemo(
    () => [
      {
        key: "all",
        label: getTranslationByLabel("all"),
      },
      {
        key: "active",
        label: getTranslationByLabel("active"),
      },
      {
        key: "future",
        label: getTranslationByLabel("future"),
      },
      {
        key: "expired",
        label: getTranslationByLabel("expired"),
      },
    ],
    []
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const filter = urlSearchParams.get(FILTER_KEY);
    if (filter && filter !== "all") {
      setSelectedFilter((filter as SystemNotification.State) ?? undefined);
    } else {
      setSelectedFilter(undefined);
    }
  }, [location.search]);

  useEffect(() => {
    // Decrease and increase with 1, because the API has zero based pagination filter
    if (page) {
      dispatch(
        getSystemNotifications({ page: page - 1, filter: selectedFilter })
      );
    }
  }, [page, selectedFilter]);

  return (
    <WithLoadingBackdrop open={isLoading}>
      <FilterBar
        filterKey={FILTER_KEY}
        items={filterItems}
        singleFilter
        withButton={selfUser && selfUser.role !== "RESIDENT"}
        label={filterBarLabel}
        onClick={() => {
          setDialogOpened(true);
        }}
      />
      <PageWrapper>
        {items.map((item) => (
          <NotificationItem
            key={item.id}
            item={item}
            onEdit={(id) => {
              setEditId(id);
              setDialogOpened(true);
            }}
          />
        ))}
        {!items.length && !isLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
          >
            <Typography variant="h3" color="textSecondary">
              {getTranslationByLabel("no-items")}
            </Typography>
          </Box>
        )}
        {!!items.length && !isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" p={1}>
            <Pagination
              onChange={(_, page) => {
                // Decrease and increase with 1, because the API has zero based pagination filter
                setPage(page, location.search);
              }}
              page={page ?? 1}
              count={pages}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Box>
        )}
      </PageWrapper>
      {selfUser && selfUser.role !== "RESIDENT" && (
        <SystemNotificationDialog
          open={dialogOpened}
          id={editId}
          onClose={(shouldRefetch?: boolean) => {
            setEditId("");
            setDialogOpened(false);
            if (shouldRefetch) {
              dispatch(
                getSystemNotifications({
                  page: page ? page - 1 : 0,
                  filter: selectedFilter,
                })
              );
            }
          }}
        />
      )}
    </WithLoadingBackdrop>
  );
};

export default Notifications;
