import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Chip,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { FormikHelpers, useFormik } from "formik";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  archiveSystemNotification,
  deleteSystemNotification,
  submitSystemNotification,
} from "../../api/systemNotifications";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";
import { Location, SystemNotification } from "../../entities";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getLocations,
  selectItems,
  selectItemsCount,
} from "../../store/locationsSlice";
import { selectSelfUser } from "../../store/userSlice";
import { getCreateSystemNotificationValidationSchema } from "../../validation";
import "./style.scss";

interface FormikValues {
  messageEn: string;
  messageFr: string;
  description: string;
  effectiveDate: moment.Moment;
  termDate: moment.Moment;
  locations: Location.Type[];
}

interface OwnProps {
  id?: string;
  open: boolean;
  onClose: (shouldRefetch?: boolean) => void;
}

const SystemNotificationDialog = ({ id, open, onClose }: OwnProps) => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();
  const locations = useAppSelector(selectItems);
  const selfUser = useAppSelector(selectSelfUser);
  const itemsCount = useAppSelector(selectItemsCount);
  const notification = useAppSelector((state) =>
    id ? state.systemNotifications.items[id] : null
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const availableLocations = useMemo(
    () =>
      locations.filter(
        (location) =>
          location.locationFeature &&
          location.locationFeature.notificationFeatureEnabled
      ),
    [locations]
  );

  useEffect(() => {
    if (
      open &&
      (!locations.length || !itemsCount || locations.length !== itemsCount)
    ) {
      dispatch(getLocations({ page: 0, itemsPerPage: 200 }));
    }
  }, [open, locations, itemsCount]);

  useEffect(() => {
    if (notification) {
      resetForm({
        values: {
          messageEn: notification.messageEn,
          messageFr: notification.messageFr,
          effectiveDate: notification.effectiveDate,
          termDate: notification.termDate,
          description: notification.description,
          locations: notification.locations,
        },
      });
    }
  }, [notification]);

  useEffect(() => {
    if (!open) {
      resetForm({ values: initialFormikValues });
    }
  }, [open]);

  const initialFormikValues = useMemo<FormikValues>(
    () => ({
      messageEn: "",
      messageFr: "",
      description: "",
      effectiveDate: moment(),
      termDate: moment(),
      locations: [],
    }),
    []
  );

  const {
    isSubmitting,
    values,
    errors,
    touched,
    resetForm,
    setSubmitting,
    setFieldTouched,
    submitForm,
    handleChange,
  } = useFormik<FormikValues>({
    initialValues: initialFormikValues,
    validationSchema: getCreateSystemNotificationValidationSchema(
      getTranslationByLabel("required-field")
    ),
    onSubmit: async (
      values: FormikValues,
      { setSubmitting }: FormikHelpers<FormikValues>
    ) => {
      try {
        setSubmitting(true);
        const item: Partial<SystemNotification.Type> = {
          messageEn: values.messageEn,
          messageFr: values.messageFr,
          effectiveDate: values.effectiveDate,
          termDate: values.termDate,
          description: values.description,
          locations: values.locations,
        };
        if (!!id) {
          item.id = id;
        }
        await submitSystemNotification(item, !id);
        setSubmitting(false);
        onClose(true);
      } catch (error: any) {
        setSubmitting(false);
        console.log("Error while save notification -> ", error);
      }
    },
  });

  const handleArchiveNotification = useCallback(async () => {
    setSubmitting(true);
    const item: Partial<SystemNotification.Type> = {
      messageEn: values.messageEn,
      messageFr: values.messageFr,
      effectiveDate: values.effectiveDate,
      termDate: values.termDate,
      description: values.description,
      locations: values.locations,
    };
    if (!!id) {
      item.id = id;
    }
    await archiveSystemNotification(item);
    setSubmitting(false);
    onClose(true);
  }, [values]);

  const handleDelete = useCallback(async () => {
    if (!id) {
      return;
    }
    setSubmitting(true);
    await deleteSystemNotification(id);
    setSubmitting(false);
    setDeleteDialogOpen(false);
    onClose(true);
  }, [id, setSubmitting]);

  const handleClose = useCallback(() => {
    resetForm({
      values: initialFormikValues,
    });
    onClose();
  }, [initialFormikValues, resetForm, onClose]);

  const isLocationSelected = useCallback(
    (id) => {
      const location = values.locations.find((el) => el.id === id);
      return !!location;
    },
    [values.locations]
  );

  const handleSelectChange = useCallback(
    ({ target }) => {
      const locationValues = values.locations;
      const idsArray =
        typeof target.value === "string"
          ? target.value.split(",")
          : target.value;
      const location = locations.find(
        (el) => el.id === idsArray[idsArray.length - 1]
      );
      if (location) {
        locationValues.push(location);
      }
      handleChange({
        target: {
          name: target.name,
          value: locationValues,
        },
      });
    },
    [locations, values.locations]
  );

  const effectiveDateTimeError = useMemo(() => {
    if (touched.effectiveDate && Boolean(errors.effectiveDate)) {
      if (typeof errors.effectiveDate === "string") {
        return errors.effectiveDate as string;
      }
    }
    return undefined;
  }, [touched, errors]);

  const termDateTimeError = useMemo(() => {
    if (touched.termDate && Boolean(errors.termDate)) {
      if (typeof errors.termDate === "string") {
        return errors.termDate as string;
      }
    }
    return undefined;
  }, [touched, errors]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        className="dialog"
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">
              {getTranslationByLabel(
                id ? "update-system-notification" : "create-system-notification"
              )}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              color="primary"
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" gutterBottom>
              {getTranslationByLabel("notification-details")}
            </Typography>
            <Box display="flex" flex={1} flexDirection="column" mb={2}>
              <Typography
                variant="caption"
                gutterBottom
                textTransform="uppercase"
                component="p"
              >
                {getTranslationByLabel("locations")}
              </Typography>
              <Box display="flex" flex={1}>
                <Box display="flex" flexDirection="column" mr={2} flex={1}>
                  <FormControl
                    error={touched.locations && Boolean(errors.locations)}
                  >
                    <Select
                      fullWidth
                      name="locations"
                      multiple
                      value={[]}
                      displayEmpty
                      renderValue={() => {
                        return (
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            style={{ fontSize: "16px" }}
                          >
                            {`${getTranslationByLabel(
                              values.locations.length
                                ? "add-location"
                                : "select-location"
                            )}...`}
                          </Typography>
                        );
                      }}
                      onChange={handleSelectChange}
                    >
                      {availableLocations.map((location) => (
                        <MenuItem
                          key={location.id}
                          value={location.id}
                          disabled={isLocationSelected(location.id)}
                        >
                          {`${location.name}, ${location.building}`}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.locations && Boolean(errors.locations) && (
                      <FormHelperText>{errors.locations}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box flex={2}>
                  {values.locations.map((location) => (
                    <Chip
                      sx={{ mr: 1, mb: 1 }}
                      key={location.id}
                      label={`${location.name}, ${location.building}`}
                      variant="outlined"
                      onDelete={() => {
                        handleChange({
                          target: {
                            name: "locations",
                            value: values.locations.filter(
                              (el) => el.id !== location.id
                            ),
                          },
                        });
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flex={1}
              alignItems="flex-start"
              justifyContent="space-between"
              mb={1}
            >
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                maxWidth="45%"
              >
                <DateTimeRow
                  date={values.effectiveDate}
                  timeError={effectiveDateTimeError}
                  dateLabel={getTranslationByLabel("start-date") ?? ""}
                  timeLabel={getTranslationByLabel("start-time") ?? ""}
                  onChange={(value) => {
                    if (value) {
                      handleChange({
                        target: {
                          name: "effectiveDate",
                          value: value,
                        },
                      });
                      if (!values.termDate || values.termDate.isBefore(value)) {
                        handleChange({
                          target: {
                            name: "termDate",
                            value: value,
                          },
                        });
                      }
                    }
                  }}
                />
                <DateTimeRow
                  timeError={termDateTimeError}
                  date={values.termDate}
                  dateLabel={getTranslationByLabel("end-date") ?? ""}
                  timeLabel={getTranslationByLabel("end-time") ?? ""}
                  minDate={values.effectiveDate}
                  onChange={(value) => {
                    if (value) {
                      handleChange({
                        target: {
                          name: "termDate",
                          value: value,
                        },
                      });
                    }
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                maxWidth="45%"
              >
                <Typography
                  variant="caption"
                  gutterBottom
                  textTransform="uppercase"
                >
                  {getTranslationByLabel("description")}
                </Typography>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  type="text"
                  multiline
                  minRows={4}
                  maxRows={4}
                  inputProps={{ maxLength: 200 }}
                  value={values.description}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.description && Boolean(errors.description)}
                  helperText={
                    (touched.description && errors.description) || " "
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" gutterBottom>
              {getTranslationByLabel("message-details")}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                maxWidth="45%"
              >
                <Typography variant="caption" gutterBottom>
                  {getTranslationByLabel("message-fr")}
                </Typography>
                <TextField
                  fullWidth
                  id="messageFr"
                  name="messageFr"
                  type="text"
                  multiline
                  minRows={4}
                  maxRows={4}
                  inputProps={{ maxLength: 250 }}
                  value={values.messageFr}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.messageFr && Boolean(errors.messageFr)}
                  helperText={(touched.messageFr && errors.messageFr) || " "}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                maxWidth="45%"
              >
                <Typography variant="caption" gutterBottom>
                  {getTranslationByLabel("message-en")}
                </Typography>
                <TextField
                  fullWidth
                  id="messageEn"
                  name="messageEn"
                  type="text"
                  multiline
                  minRows={4}
                  maxRows={4}
                  inputProps={{ maxLength: 250 }}
                  value={values.messageEn}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.messageEn && Boolean(errors.messageEn)}
                  helperText={(touched.messageEn && errors.messageEn) || " "}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {!!id && (
            <>
              {selfUser && selfUser.role === "MARVELOCK_ADMIN" && (
                <Button
                  className="button"
                  variant="text"
                  disabled={isSubmitting}
                  onClick={() => {
                    setDeleteDialogOpen(true);
                  }}
                >
                  {getTranslationByLabel("delete")}
                </Button>
              )}
              {!!notification && !notification.archived && (
                <Button
                  className="button"
                  variant="outlined"
                  disabled={isSubmitting}
                  onClick={handleArchiveNotification}
                >
                  {getTranslationByLabel("archive-notification")}
                </Button>
              )}
            </>
          )}
          <Button
            className="button"
            variant="contained"
            autoFocus
            disabled={isSubmitting}
            onClick={submitForm}
          >
            {getTranslationByLabel("submit")}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        // disabled={isSubmitting}
        onConfirm={handleDelete}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};

interface DateTimeRowProps {
  date: moment.Moment;
  dateLabel: string;
  timeLabel: string;
  minDate?: moment.Moment;
  timeError?: string;
  onChange: (value: moment.Moment | null) => void;
}

const DateTimeRow = ({
  date,
  dateLabel,
  timeLabel,
  minDate,
  timeError,
  onChange,
}: DateTimeRowProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Box display="flex" flexDirection="column" flex={1} maxWidth="60%">
        <Typography variant="caption" gutterBottom textTransform="uppercase">
          {dateLabel}
        </Typography>
        <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          disablePast
          minDate={minDate}
          value={date}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <FormHelperText> </FormHelperText>
      </Box>
      <Box display="flex" flexDirection="column" flex={1} maxWidth="30%">
        <Typography variant="caption" gutterBottom textTransform="uppercase">
          {timeLabel}
        </Typography>
        <TimePicker
          value={date}
          onChange={onChange}
          ampm={false}
          minutesStep={5}
          renderInput={(params) => <TextField {...params} />}
        />
        <FormHelperText error>{timeError ?? " "}</FormHelperText>
      </Box>
    </Box>
  );
};

export default SystemNotificationDialog;
