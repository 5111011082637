import { faClose, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { fetchLocationFilters, LocationFilters } from "../api/locations";
import Select from "../common/Select";
import { SortDirection } from "../filters";

export enum FilterKeys {
  Company = "company",
  Location = "location-name",
  Building = "building",
  SortDirection = "sortDirection",
}

interface OwnProps {
  onCreateClick?: () => void;
  withCreateButton?: boolean;
}
const LocationFilterBar = ({ onCreateClick, withCreateButton }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [sortDirection, setSortDirection] = useState<SortDirection>("ASC");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState("");

  const [companies, setCompanies] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [buildings, setBuildings] = useState<string[]>([]);

  const fetchSelectItems = useCallback(
    async (key: keyof LocationFilters) => {
      switch (key) {
        case "buildingNames": {
          const response = await fetchLocationFilters(
            selectedCompany,
            selectedLocation
          );
          const items = response[key].filter((el) => !!el);
          setBuildings(items);
          break;
        }
        case "companyNames": {
          const response = await fetchLocationFilters();
          const items = response[key].filter((el) => !!el);
          setCompanies(items);
          break;
        }
        case "locationNames": {
          const response = await fetchLocationFilters(selectedCompany);
          const items = response[key].filter((el) => !!el);
          setLocations(items);
          break;
        }
      }
    },
    [selectedLocation, selectedCompany, selectedBuilding]
  );

  useEffect(() => {
    fetchSelectItems("companyNames");
  }, []);

  useEffect(() => {
    setSelectedLocation("");
    setSelectedBuilding("");
    if (selectedCompany) {
      fetchSelectItems("locationNames");
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedLocation) {
      fetchSelectItems("buildingNames");
    }
  }, [selectedLocation]);

  const handleFilterClick = useCallback(() => {
    if (selectedCompany) {
      const newUrlSearchParams = new URLSearchParams();
      newUrlSearchParams.append(FilterKeys.Company, selectedCompany);
      newUrlSearchParams.append(FilterKeys.SortDirection, sortDirection);
      if (selectedLocation) {
        newUrlSearchParams.append(FilterKeys.Location, selectedLocation);
      }
      if (selectedBuilding) {
        newUrlSearchParams.append(FilterKeys.Building, selectedBuilding);
      }
      history.replace({
        pathname: location.pathname,
        search: newUrlSearchParams.toString(),
      });
    }
  }, [
    history,
    location,
    selectedCompany,
    selectedLocation,
    selectedBuilding,
    sortDirection,
  ]);

  const handleClearClick = useCallback(() => {
    setSelectedCompany("");
    setSelectedLocation("");
    setSelectedBuilding("");
    history.replace({
      pathname: location.pathname,
      search: "",
    });
  }, [history, location]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      my={2}
    >
      <Box flex={2}>
        <Select
          sx={{
            maxWidth: "95%",
          }}
          fullWidth
          value={selectedCompany}
          displayEmpty
          onChange={(event) => {
            setSelectedCompany(event.target.value as string);
          }}
          placeholder={`${getTranslationByLabel("select-company")}...`}
        >
          {companies.map((el) => (
            <MenuItem key={el} value={el} disabled={el === selectedCompany}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box flex={2}>
        <Select
          sx={{
            maxWidth: "95%",
          }}
          fullWidth
          value={selectedLocation}
          disabled={!selectedCompany}
          displayEmpty
          onChange={(event) => {
            setSelectedLocation(event.target.value as string);
          }}
          placeholder={`${getTranslationByLabel("select-location")}...`}
        >
          <MenuItem value="" disabled={!selectedLocation}>
            {getTranslationByLabel("all")}
          </MenuItem>
          {locations.map((el) => (
            <MenuItem key={el} value={el} disabled={el === selectedLocation}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box flex={1}>
        <Select
          sx={{
            maxWidth: "95%",
          }}
          fullWidth
          value={selectedBuilding}
          disabled={!selectedLocation}
          displayEmpty
          onChange={(event) => {
            setSelectedBuilding(event.target.value as string);
          }}
          placeholder={`${getTranslationByLabel("select-building")}...`}
        >
          <MenuItem value="" disabled={!selectedBuilding}>
            {getTranslationByLabel("all")}
          </MenuItem>
          {buildings.map((el) => (
            <MenuItem key={el} value={el} disabled={el === selectedBuilding}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box flex={1}>
        <Select
          sx={{
            maxWidth: "95%",
          }}
          fullWidth
          value={sortDirection}
          displayEmpty
          onChange={(event) => {
            setSortDirection(event.target.value as any);
          }}
          placeholder={`${getTranslationByLabel("sort")}...`}
        >
          <MenuItem value="ASC" disabled={sortDirection === "ASC"}>
            {getTranslationByLabel("asc")}
          </MenuItem>
          <MenuItem value="DESC" disabled={sortDirection === "DESC"}>
            {getTranslationByLabel("desc")}
          </MenuItem>
        </Select>
      </Box>
      {!!selectedCompany && (
        <Box mr={1}>
          <IconButton
            style={{ height: 45, width: 45 }}
            onClick={handleClearClick}
            color="error"
          >
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </Box>
      )}
      <Box mr={withCreateButton && onCreateClick ? 2 : 0}>
        <IconButton
          disabled={!selectedCompany}
          style={{ height: 45, width: 45 }}
          onClick={handleFilterClick}
          color="primary"
        >
          <FontAwesomeIcon icon={faFilter} />
        </IconButton>
      </Box>
      {withCreateButton && onCreateClick && (
        <Box flex={1}>
          <Button
            variant="contained"
            fullWidth
            className="button"
            style={{ height: 55 }}
            onClick={onCreateClick}
          >
            {getTranslationByLabel("create-new")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default LocationFilterBar;
