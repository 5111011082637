import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "../../common/CopyToClipboardButton";
import ItemCard from "../../common/ItemCard";
import { User } from "../../entities";
import { useAppSelector } from "../../hooks";
import { selectSelfUser } from "../../store/userSlice";
import "./style.scss";

interface OwnProps {
  item: User.Type;
  onEdit: () => void;
}
const UserItem = ({
  item: { id, firstName, lastName, type, email, phone },
  onEdit,
}: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const selfUser = useAppSelector(selectSelfUser);

  const canEditUser = useMemo(() => {
    if (!selfUser) {
      return false;
    }
    if (selfUser.email === email) {
      return true;
    }
    if (selfUser.role === "MARVELOCK_ADMIN" && type !== "MARVELOCK_ADMIN") {
      return true;
    }
    if (
      selfUser.role === "SITE_MANAGER" &&
      (type === "BUILDING_MANAGER" || type === "RESIDENT")
    ) {
      return true;
    }
    if (selfUser.role === "BUILDING_MANAGER" && type === "RESIDENT") {
      return true;
    }
    return false;
  }, [selfUser, type]);

  return (
    <div className="user-item-conteiner">
      <ItemCard>
        <Box display="flex" alignItems="center">
          <div className={classNames("item", "large")}>
            <Typography textAlign="left" variant="h6" color="primary">
              {firstName} {lastName}
            </Typography>
            <Typography
              textAlign="left"
              variant="caption"
              color="primary"
              component="p"
            >
              {getTranslationByLabel(type)}
            </Typography>
          </div>
          <TextItem
            label={getTranslationByLabel("email")}
            description={
              selfUser?.role !== "MARVELOCK_ADMIN" && type === "MARVELOCK_ADMIN"
                ? ""
                : email
            }
          />
          <TextItem
            label={getTranslationByLabel("phone")}
            description={
              selfUser?.role !== "MARVELOCK_ADMIN" && type === "MARVELOCK_ADMIN"
                ? ""
                : phone
            }
          />
          <CopyToClipboardButton
            className={classNames("small", "button")}
            fullWidth
            variant="outlined"
            valueToCopy={id}
          />
          <Button
            className={classNames("small", "button", {
              hidden: !canEditUser,
            })}
            variant="contained"
            fullWidth
            onClick={() => {
              if (canEditUser) {
                onEdit();
              }
            }}
          >
            {getTranslationByLabel("edit")}
          </Button>
        </Box>
      </ItemCard>
    </div>
  );
};

interface TextItemProps {
  label: string;
  description: string;
}

const TextItem = ({ label, description }: TextItemProps) => {
  return (
    <div className={classNames("item", "large")}>
      <Typography
        component="p"
        textAlign="left"
        textTransform="uppercase"
        variant="caption"
        color="primary"
      >
        {label}
      </Typography>
      <Typography
        className="description-label"
        textAlign="left"
        variant="h6"
        color="primary"
      >
        {description}
      </Typography>
    </div>
  );
};

export default UserItem;
