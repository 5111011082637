import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import ItemCard from "../../common/ItemCard";
import { SystemNotification } from "../../entities";
import { useAppSelector } from "../../hooks";
import { AvailableLanguages } from "../../i18n/config";
import { selectSelfUser } from "../../store/userSlice";
import "./style.scss";

interface OwnProps {
  item: SystemNotification.Type;
  onEdit: (id: string) => void;
}
const NotificationItem = ({
  item: {
    id,
    locations,
    effectiveDate,
    termDate,
    messageEn,
    messageFr,
    description,
    creatorRole,
    status,
    archived,
  },
  onEdit,
}: OwnProps) => {
  const { t: getTranslationByLabel, i18n } = useTranslation();
  const selfUser = useAppSelector(selectSelfUser);

  let statusClassName;
  if (archived) {
    statusClassName = "expired";
  } else if (status === "ACTIVE") {
    statusClassName = "active";
  } else if (status === "EXPIRED") {
    statusClassName = "expired";
  } else if (status === "FUTURE") {
    statusClassName = "future";
  } else {
    statusClassName = "attention";
  }
  return (
    <div className="notification-item-conteiner">
      <ItemCard>
        <div className={classNames("statusContainer", statusClassName)}>
          <Typography
            align="center"
            variant="body1"
            color={
              archived
                ? "primary"
                : status === "ACTIVE" || status === "FUTURE"
                ? "white"
                : "primary"
            }
          >
            {getTranslationByLabel(
              archived ? "archived" : status.toLowerCase()
            ).toUpperCase()}
          </Typography>
        </div>
        <Box display="flex" alignItems="flex-start">
          <div className={classNames("item", "large")}>
            <Typography textAlign="left" variant="h6" color="primary">
              {!!locations.length
                ? `${locations[0].name}, ${locations[0].building}`
                : "N/A"}
            </Typography>
            <Typography
              textAlign="left"
              variant="caption"
              color="primary"
              component="p"
            >
              {effectiveDate.format("DD.MM.YYYY")} -{" "}
              {termDate.format("DD.MM.YYYY")}
            </Typography>
          </div>
          <TextItem
            label={getTranslationByLabel("message")}
            message={
              i18n.language.includes(AvailableLanguages.FR)
                ? messageFr
                : messageEn
            }
          />
          <TextItem
            label={getTranslationByLabel("description")}
            message={description ?? "N/A"}
          />
          <TextItem
            label={getTranslationByLabel("from")}
            message={creatorRole ? getTranslationByLabel(creatorRole) : "N/A"}
          />
          {selfUser && selfUser.role !== "RESIDENT" && (
            <Button
              className={classNames("small", "button")}
              variant="outlined"
              fullWidth
              onClick={() => {
                onEdit(id);
              }}
            >
              {getTranslationByLabel("edit")}
            </Button>
          )}
        </Box>
      </ItemCard>
    </div>
  );
};

interface TextItemProps {
  label: string;
  message: string;
}

const TextItem = ({ label, message }: TextItemProps) => {
  return (
    <div className={classNames("item", "small")}>
      <Typography
        component="p"
        textAlign="left"
        textTransform="uppercase"
        variant="caption"
        color="primary"
      >
        {label}
      </Typography>
      <Typography
        textAlign="left"
        variant="body1"
        color="primary"
        className="text-item-label"
      >
        {message}
      </Typography>
    </div>
  );
};

export default NotificationItem;
