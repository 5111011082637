import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

export enum HealthCheckType {
  SINGLE = 1,
  ALL = 2,
}

interface OwnProps {
  open: boolean;
  onClose: () => void;
  type?: HealthCheckType;
}

const HealthCheckDialog = ({ open, type, onClose }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>{getTranslationByLabel("send-health-check")}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {getTranslationByLabel("health-check-submit", {
            seconds: type === HealthCheckType.SINGLE ? "10" : "30",
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HealthCheckDialog;
