import { Link } from "react-router-dom";
import { BASE_PATH } from "../routes";

type OwnProps = {};

const NotFound = ({}: OwnProps) => {
  return (
    <div>
      <p>Page not found!</p>
      <p>
        <Link to={BASE_PATH}>Home</Link>
      </p>
    </div>
  );
};

export default NotFound;
