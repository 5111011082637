import React from "react";
import Box, { BoxProps } from "@mui/material/Box";

const PageWrapper: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="calc(100% - 84px)"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default PageWrapper;
