import ServiceOrder from "./ServiceOrder";
import User from "./User";

export namespace Report {
  export type Type = {
    locationName: string;
    buildingName: string;
    companyName: string;
    serviceOrderEffectiveDate: moment.Moment;
    serviceOrderState: "active" | "inactive";
    serviceOrder: ServiceOrder.Type;
    user: User.Type;
  };
}

export default Report;
