import {
  Box,
  Button,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { cleanAccessToken } from "../../api";
import NotificationSettingsRow from "../../common/NotificationSettingsRow";
import OutlineCard from "../../common/OutlineCard";
import { ACCESS_TOKEN_KEY } from "../../constants";
import { User } from "../../entities";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AvailableLanguages } from "../../i18n/config";
import { LOGIN_PATH } from "../../routes";
import { getSelfUser, selectSelfUser } from "../../store/userSlice";
import "./style.scss";

interface OwnProps {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
}

const SettingsPopover = ({ anchorEl, onClose }: OwnProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { t: getTranslationByLabel, i18n } = useTranslation();
  const user = useAppSelector(selectSelfUser);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleLogout = async () => {
    cleanAccessToken();
    await oktaAuth.signOut({
      clearTokensBeforeRedirect: true,
    });
    setTimeout(() => {
      history.replace(LOGIN_PATH);
    }, 0);
  };

  const handleConfigurationChange = (
    key: keyof User.UserConfiguration,
    value: boolean | "en" | "fr"
  ) => {
    if (user && user.userConfiguration) {
      const newConfiguration: User.UserConfiguration = {
        ...user.userConfiguration,
        [key]: value,
      };
      console.log(newConfiguration);
      axios
        .put(`/v1/user/configurations/${user.id}`, newConfiguration)
        .then(() => {
          dispatch(
            getSelfUser(localStorage.getItem(ACCESS_TOKEN_KEY) || "")
          ).then(() => {
            if (value === "en") {
              i18n.changeLanguage(AvailableLanguages.EN);
            } else if (value === "fr") {
              i18n.changeLanguage(AvailableLanguages.FR);
            }
            // dispatch(
            //   showNotification({
            //     type: EnumNotificationType.Success,
            //     text: getTranslationByLabel("successfully_updated"),
            //   })
            // );
            // setLoadingConfiguration(false);
          });
        })
        .catch((error) => {
          console.log("Error updating user -> ", error);
          // setLoadingConfiguration(false);
        });
    }
  };

  if (!user || !user.userConfiguration) {
    return null;
  }
  const open = Boolean(anchorEl);
  const menuOpen = Boolean(menuAnchorEl);
  return (
    <Popover
      id={open ? "settings-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: 0,
        },
      }}
    >
      <PopoverArrow />
      <Box display="flex" flexDirection="column" className="inner-container">
        <Typography variant="h5" color="primary">
          {user.firstName || "N/A"} {user.lastName || "N/A"}
        </Typography>
        <Typography variant="subtitle2" color="primary">
          {user.email}
        </Typography>
        <div className="language-container">
          <Typography variant="subtitle1" color="primary" component="span">
            {getTranslationByLabel("select-language")}
          </Typography>
          <Button
            id="basic-button"
            aria-controls={menuOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            variant="text"
            color="primary"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setMenuAnchorEl(event.currentTarget);
            }}
          >
            {i18n.language.toUpperCase()}
          </Button>
          <Menu
            open={menuOpen}
            onClose={onClose}
            id="basic-menu"
            anchorEl={menuAnchorEl}
          >
            <MenuItem
              selected={!i18n.language.includes("fr")}
              onClick={() => {
                setMenuAnchorEl(null);
                onClose();
                handleConfigurationChange("language", "en");
              }}
            >
              EN
            </MenuItem>
            <MenuItem
              selected={i18n.language.includes("fr")}
              onClick={() => {
                setMenuAnchorEl(null);
                onClose();
                handleConfigurationChange("language", "fr");
              }}
            >
              FR
            </MenuItem>
          </Menu>
        </div>
        <OutlineCard
          disabled={!user.lockerFeatureEnabled}
          label={getTranslationByLabel("locker-settings").toUpperCase()}
        >
          <NotificationSettingsRow
            disabled={!user.lockerFeatureEnabled}
            label={getTranslationByLabel("receive-email-notification")}
            isSwitchOn={user.userConfiguration.lockerEmailNotificationsEnabled}
            onToggleSwitch={(value) => {
              handleConfigurationChange(
                "lockerEmailNotificationsEnabled",
                value
              );
            }}
          />
          <NotificationSettingsRow
            disabled={!user.lockerFeatureEnabled}
            label={getTranslationByLabel("receive-mobile-notification")}
            isSwitchOn={user.userConfiguration.lockerAppNotificationsEnabled}
            onToggleSwitch={(value) => {
              handleConfigurationChange("lockerAppNotificationsEnabled", value);
            }}
          />
          <NotificationSettingsRow
            disabled={!user.lockerFeatureEnabled}
            label={getTranslationByLabel("receive-sms-notification")}
            isSwitchOn={user.userConfiguration.smsLockerNotificationsEnabled}
            onToggleSwitch={(value) => {
              handleConfigurationChange("smsLockerNotificationsEnabled", value);
            }}
          />
        </OutlineCard>
        <OutlineCard
          disabled={!user.notificationFeatureEnabled}
          className="second-container"
          label={getTranslationByLabel("system-notifications").toUpperCase()}
        >
          <NotificationSettingsRow
            disabled={!user.notificationFeatureEnabled}
            label={getTranslationByLabel("receive-email-notification")}
            isSwitchOn={user.userConfiguration.emailNotificationsEnabled}
            onToggleSwitch={(value) => {
              handleConfigurationChange("emailNotificationsEnabled", value);
            }}
          />
          <NotificationSettingsRow
            disabled={!user.notificationFeatureEnabled}
            label={getTranslationByLabel("receive-mobile-notification")}
            isSwitchOn={user.userConfiguration.appNotificationsEnabled}
            onToggleSwitch={(value) => {
              handleConfigurationChange("appNotificationsEnabled", value);
            }}
          />
          <NotificationSettingsRow
            disabled={!user.notificationFeatureEnabled}
            label={getTranslationByLabel("receive-sms-notification")}
            isSwitchOn={user.userConfiguration.smsNotificationsEnabled}
            onToggleSwitch={(value) => {
              handleConfigurationChange("smsNotificationsEnabled", value);
            }}
          />
        </OutlineCard>
        <Typography
          variant="body2"
          color="textSecondary"
          className="contact-provider-label"
        >
          {getTranslationByLabel("contact-provider")}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {/* <Button
            className="button"
            variant="text"
            onClick={handleDeleteAccount}
          >
            {getTranslationByLabel("delete-account")}
          </Button> */}
          <Button variant="outlined" onClick={handleLogout} className="button">
            {getTranslationByLabel("sign-out")}
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          mt={2}
        >
          <Typography variant="body2" align="center">
            {getTranslationByLabel("notify_issue")}
          </Typography>
          <Typography
            variant="body1"
            fontFamily="Avenir-Black"
            textAlign="center"
          >
            <a href="mailto:support@marvelock.com">support@marvelock.com</a>
          </Typography>
          <Typography
            variant="body1"
            fontFamily="Avenir-Black"
            textAlign="center"
          >
            <a href="https://www.marvelock.fr" target="_blank">
              www.marvelock.fr
            </a>
          </Typography>
        </Box>
      </Box>
    </Popover>
  );
};

const PopoverArrow = () => (
  <Box
    sx={{
      position: "relative",
      mt: "10px",
      "&::before": {
        backgroundColor: "white",
        content: '""',
        display: "block",
        position: "absolute",
        width: 12,
        height: 12,
        top: -6,
        transform: "rotate(45deg)",
        left: 16,
      },
    }}
  />
);

export default SettingsPopover;
