import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from ".";
import {
  fetchLockers,
  refreshLocker as refreshLockerAPI,
  LockerResponse,
} from "../api/lockers";
import { Locker } from "../entities";
import { EnumNotificationType, showNotification } from "./notificationSlice";

export interface StateItems {
  [id: string]: Locker.Type;
}

export interface LockersState {
  items: StateItems;
  loading: boolean;
  pages: number;
  elements: number;
  error?: string;
}

const initialState: LockersState = {
  items: {},
  pages: 0,
  elements: 0,
  loading: false,
};

export const refreshLocker = createAsyncThunk<Locker.Type, string>(
  "lockers/refreshLocker",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setFetchLockersError());
      const response: Locker.Type = await refreshLockerAPI(id);
      if (!response) {
        return rejectWithValue("Something went wrong. Please try again later.");
      }
      const result = {
        ...response,
        bins: response.bins.sort((a, b) => a.number - b.number),
        installationDate: moment(response.installationDate),
      };
      return result;
    } catch (error: any) {
      console.log("Error -> ", error);
      dispatch(
        showNotification({
          text: "Something went wrong. Please try again later.",
          type: EnumNotificationType.Error,
        })
      );
      return rejectWithValue(error.response?.data);
    }
  }
);

interface IGetLockers extends Omit<LockerResponse, "content"> {
  items: StateItems;
}

export const getLockers = createAsyncThunk<IGetLockers, number>(
  "lockers/getLockers",
  async (page, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setFetchLockersError());
      const reponse: LockerResponse = await fetchLockers(page, 10);
      if (!reponse.content.length) {
        return rejectWithValue("Something went wrong. Please try again later.");
      }
      const content: Locker.Type[] = reponse.content.map((el) => ({
        ...el,
        bins: el.bins.sort((a, b) => a.number - b.number),
        installationDate: moment(el.installationDate),
      }));

      const items: StateItems = {};
      content.forEach((item) => {
        items[item.id] = item;
      });
      return {
        ...reponse,
        items,
      };
    } catch (error: any) {
      console.log("Error -> ", error);
      dispatch(
        showNotification({
          text: "Something went wrong. Please try again later.",
          type: EnumNotificationType.Error,
        })
      );
      return rejectWithValue(error.response?.data);
    }
  }
);

export const lockersSlice = createSlice({
  name: "lockers",
  initialState,
  reducers: {
    setLockers: (state, action: PayloadAction<StateItems>) => {
      state.items = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFetchLockersError: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLockers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLockers.fulfilled, (state, action) => {
      state.items = action.payload.items;
      state.pages = action.payload.totalPages;
      state.elements = action.payload.totalElements;
      state.loading = false;
    });
    builder.addCase(getLockers.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
    builder.addCase(refreshLocker.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refreshLocker.fulfilled, (state, action) => {
      state.items[action.payload.id] = action.payload;
      state.loading = false;
    });
    builder.addCase(refreshLocker.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { setLockers, setFetchLockersError, setLoading } =
  lockersSlice.actions;
export const selectItems = (state: RootState) => {
  return Object.keys(state.lockers.items).map(
    (key) => state.lockers.items[key]
  );
};
export const selectIsLoading = (state: RootState) => state.lockers.loading;
export const selectPages = (state: RootState) => state.lockers.pages;
export const selectItemsCount = (state: RootState) => state.lockers.elements;
export const selectItemById = (state: RootState, id?: string) => {
  if (!id) {
    return null;
  }
  return state.lockers.items[id];
};
export const selectFetchItemsError = (state: RootState) => state.lockers.error;

export default lockersSlice.reducer;
