import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from ".";
import { fetchReports } from "../api/reports";
import { fetchServiceOrderById } from "../api/serviceOrders";
import { fetchUserById } from "../api/user";
import { Report } from "../entities";
import { SortDirection } from "../filters";
import { EnumNotificationType, showNotification } from "./notificationSlice";
import { selectItems as selectUserItems } from "./userSlice";

export interface ReportsState {
  items: Report.Type[];
  pages: number;
  elements: number;
  loading: boolean;
  error?: string;
}

const initialState: ReportsState = {
  items: [],
  pages: 0,
  elements: 0,
  loading: false,
};

interface IGetPorts {
  items: Report.Type[];
  pages: number;
  elements: number;
}

interface GetReportParams {
  page: number;
  sortDirection?: SortDirection;
  companyName?: string;
  buildingName?: string;
  locationName?: string;
}

export const getReports = createAsyncThunk<
  IGetPorts,
  GetReportParams,
  {
    state: RootState;
  }
>(
  "reports/getReports",
  async (
    { page, sortDirection = "ASC", companyName, buildingName, locationName },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      dispatch(setFetchReportsError());
      dispatch(setLoading(true));
      const response = await fetchReports(
        page,
        10,
        sortDirection,
        companyName,
        buildingName,
        locationName
      );
      if (!response.content.length) {
        const responseToReturn: IGetPorts = {
          items: [],
          pages: 0,
          elements: 0,
        };
        return responseToReturn;
      }
      const serviceOrderItems = getState().serviceOrders.items;
      const userItems = selectUserItems(getState());

      const itemsPromises = response.content.map(async (report) => {
        let serviceOrder = serviceOrderItems.find(
          (el) => el.id === report.serviceOrderId
        );
        let user = userItems.find((el) => el.id === report.userAssignedTo);
        if (!serviceOrder) {
          serviceOrder = await fetchServiceOrderById(report.serviceOrderId);
        }
        if (!user) {
          user = await fetchUserById(report.userAssignedTo);
        }
        const item: Report.Type = {
          ...report,
          serviceOrderEffectiveDate: moment(report.serviceOrderEffectiveDate),
          serviceOrder,
          companyName: report.companyName ?? "N/A",
          user,
        };
        return item;
      });

      let items: Report.Type[] = await Promise.all(itemsPromises);
      const responseToReturn: IGetPorts = {
        items,
        pages: response.totalPages,
        elements: response.totalElements,
      };
      return responseToReturn;
    } catch (error: any) {
      console.log("Error -> ", error);
      dispatch(
        showNotification({
          text: "Something went wrong. Please try again later.",
          type: EnumNotificationType.Error,
        })
      );
      return rejectWithValue(error.response?.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const loading = getState().reports.loading;
      return !loading;
    },
  }
);

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReports: (state, action: PayloadAction<Report.Type[]>) => {
      state.items = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFetchReportsError: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReports.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.items = action.payload.items;
      state.pages = action.payload.pages;
      state.elements = action.payload.elements;
      state.loading = false;
    });
    builder.addCase(getReports.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { setReports, setFetchReportsError, setLoading } =
  reportsSlice.actions;
export const selectItems = (state: RootState) => state.reports.items;
export const selectPages = (state: RootState) => state.reports.pages;
export const selectIsLoading = (state: RootState) => state.reports.loading;
export const selectItemsCount = (state: RootState) => state.reports.elements;
export const selectFetchItemsError = (state: RootState) => state.reports.error;

export default reportsSlice.reducer;
