import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Pagination, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PageWrapper from "../common/PageWrapper";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import FilterBar, { FilterItem } from "../components/FilterBar";
import UserDialog from "../components/UserDialog";
import UserItem from "../components/UserItem";
import { User } from "../entities";
import { useAppDispatch, useAppSelector } from "../hooks";
import { usePagination } from "../hooks/usePagination";
import {
  getUsers,
  selectIsLoading,
  selectItems,
  selectItemsCount,
  selectPages,
  selectSelfUser,
} from "../store/userSlice";

const FILTER_KEY = "roles";

const Users = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t: getTranslationByLabel } = useTranslation();
  const selfUser = useAppSelector(selectSelfUser);
  const isLoading = useAppSelector(selectIsLoading);
  const items = useAppSelector(selectItems);
  const pages = useAppSelector(selectPages);
  const count = useAppSelector(selectItemsCount);
  const [page, setPage] = usePagination();
  const [dialogOpened, setDialogOpened] = useState(false);
  const [editId, setEditId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedRoles, setSelectedRoles] = useState<User.UserType[]>([]);

  const debounceFn = useCallback(debounce(handleDebounceFn, 200), []);

  function handleDebounceFn(
    page: number | undefined,
    selectedRoles: User.UserType[],
    firstName: string,
    lastName: string
  ) {
    // Decrease and increase with 1, because the API has zero based pagination filter
    if (page !== undefined) {
      dispatch(
        getUsers({
          page,
          roles: selectedRoles,
          firstName,
          lastName,
        })
      );
    }
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    setSelectedRoles(urlSearchParams.getAll(FILTER_KEY) as User.UserType[]);
  }, [location.search]);

  useEffect(() => {
    debounceFn(page ? page - 1 : 0, selectedRoles, firstName, lastName);
  }, [page, selectedRoles, firstName, lastName]);

  const filterItems: FilterItem[] = useMemo(() => {
    if (!selfUser) {
      return [];
    }
    const result = [
      {
        key: "all",
        label: getTranslationByLabel("all"),
      },
    ];
    if (selfUser.role === "SITE_MANAGER") {
      result.push({
        key: "BUILDING_MANAGER",
        label: getTranslationByLabel("building-managers"),
      });
    } else if (selfUser.role === "MARVELOCK_ADMIN") {
      result.push({
        key: "MARVELOCK_ADMIN",
        label: getTranslationByLabel("administrator"),
      });
      result.push({
        key: "BUILDING_MANAGER",
        label: getTranslationByLabel("building-managers"),
      });
      result.push({
        key: "SITE_MANAGER",
        label: getTranslationByLabel("site-managers"),
      });
    }
    result.push({
      key: "RESIDENT",
      label: getTranslationByLabel("residents"),
    });
    return result;
  }, [selfUser]);

  const filterBarLabel = useMemo(() => {
    if (isLoading || !page) {
      return getTranslationByLabel("loading");
    }
    let labelNumber = page * 10;
    if (count < 10 || labelNumber > count) {
      labelNumber = count;
    }
    return `${labelNumber}/${count}`;
  }, [count, page, isLoading]);

  return (
    <WithLoadingBackdrop open={isLoading}>
      <FilterBar
        filterKey={FILTER_KEY}
        label={filterBarLabel}
        items={filterItems}
        withButton
        singleFilter
        onClick={() => {
          setDialogOpened(true);
        }}
      />
      <Box display="flex" alignItems="center" flexDirection="row" mb={2}>
        <TextField
          type="text"
          placeholder={`${getTranslationByLabel("enter-first-name")}...`}
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          size="small"
          style={{ marginRight: 8 }}
        />
        <TextField
          type="text"
          placeholder={`${getTranslationByLabel("enter-last-name")}...`}
          value={lastName}
          size="small"
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          style={{ marginRight: 8 }}
        />
        {(!!firstName || !!lastName) && (
          <IconButton
            size="small"
            onClick={() => {
              setFirstName("");
              setLastName("");
            }}
            color="error"
          >
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        )}
      </Box>
      <PageWrapper>
        {items.map((item) => (
          <UserItem
            key={item.id}
            item={item}
            onEdit={() => {
              setEditId(item.id);
              setDialogOpened(true);
            }}
          />
        ))}
        {!items.length && !isLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
          >
            <Typography variant="h3" color="textSecondary">
              {getTranslationByLabel("no-items")}
            </Typography>
          </Box>
        )}
        {!!items.length && !isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" p={1}>
            <Pagination
              onChange={(_, page) => {
                setPage(page, location.search);
              }}
              page={page ?? 1}
              count={pages}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Box>
        )}
      </PageWrapper>
      <UserDialog
        open={dialogOpened}
        id={editId}
        onClose={(shouldRefetch?: boolean) => {
          setEditId("");
          setDialogOpened(false);
          if (shouldRefetch) {
            dispatch(
              getUsers({
                page: page ? page - 1 : 0,
                roles: selectedRoles,
                firstName,
                lastName,
              })
            );
          }
        }}
      />
    </WithLoadingBackdrop>
  );
};

export default Users;
