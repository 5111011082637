import { Box, Pagination, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PageWrapper from "../common/PageWrapper";
import WithLoadingBackdrop from "../common/WithLoadingBackdrop";
import LocationFilterBar from "../components/LocationFilterBar";
import ReportItem from "../components/ReportItem";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useLocationFilters } from "../hooks/useLocationFilters";
import { usePagination } from "../hooks/usePagination";
import {
  getReports,
  selectIsLoading,
  selectItems,
  selectPages,
} from "../store/reportsSlice";

const Reports = () => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();
  const location = useLocation();
  const isLoading = useAppSelector(selectIsLoading);
  const items = useAppSelector(selectItems);
  const pages = useAppSelector(selectPages);
  const [page, setPage] = usePagination();
  const { sortDirection, selectedCompany, selectedLocation, selectedBuilding } =
    useLocationFilters();

  useEffect(() => {
    // Decrease and increase with 1, because the API has zero based pagination filter
    if (page) {
      dispatch(
        getReports({
          page: page - 1,
          companyName: selectedCompany,
          locationName: selectedLocation,
          buildingName: selectedBuilding,
          sortDirection,
        })
      );
    }
  }, [
    page,
    selectedCompany,
    selectedLocation,
    selectedBuilding,
    sortDirection,
  ]);

  return (
    <WithLoadingBackdrop open={isLoading}>
      <PageWrapper>
        <LocationFilterBar />
        {items.map((item) => (
          <ReportItem
            key={`${item.serviceOrder.id}-${item.user.id}`}
            item={item}
          />
        ))}
        {!items.length && !isLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
          >
            <Typography variant="h3" color="textSecondary">
              {getTranslationByLabel("no-items")}
            </Typography>
          </Box>
        )}
        {!!items.length && !isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" p={1}>
            <Pagination
              onChange={(_, page) => {
                // Decrease and increase with 1, because the API has zero based pagination filter
                setPage(page, location.search);
              }}
              page={page ?? 1}
              count={pages}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Box>
        )}
      </PageWrapper>
    </WithLoadingBackdrop>
  );
};

export default Reports;
